/**
 * -Details Preview-linkCode=as3
 * -Details Impression-linkCode=as2
 * -Offer Listing Preview-linkCode=am3
 * -Offer Listing Impression-linkCode=am2
 * -Enhanced Display-linkCode=as1 linkCode=am1
 */
var hrefTemplate = "http://www.amazon.com/gp/product/{{asin}}?ie=UTF8&creativeASIN={{asin}}&linkCode=xm2&tag={{affiliateId}}",
    imageHrefTemplate = 'http://www.amazon.com/gp/product/{{asin}}/ref=as_li_tl?ie=UTF8' +
        '&creative=9325&creativeASIN={{asin}}&linkCode=as2&tag={{affiliateId}}&linkId=2WZCXQG2L34GVCBJ',
    imgSrcTemplate = 'http://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN={{asin}}' +
        '&Format=_SL110_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag={{affiliateId}}',
    amazonAdSystemUrlTemplate = 'http://ir-na.amazon-adsystem.com/e/ir?t={{affiliateId}}&l=as2&o=1&a={{asin}}',
    iframeSrcTemplate = '//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1' +
        '&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id={{affiliateId}}' +
        '&marketplace=amazon&region=US&placement=0615991424&asins={{asin}}&linkId=SIQODEXA4LNMOY4Q' +
        '&show_border=true&link_opens_in_new_window=true';

angular.module("hnAmazon", ["angular-clipboard", "dialogs.main", "ngSanitize", "uiw-fallback-image"])
    /**
     * @ngDoc controller
     * @name AmazonLinkDialogCtrl
     * @description
     */
    .controller("AmazonLinkDialogCtrl", ["$interpolate", "$link", "$sce", "$scope", function($interpolate, $link, $sce, $scope) {
        var hrefFn = $interpolate(hrefTemplate),
            imageHrefFn = $interpolate(imageHrefTemplate),
            imgSrcFn = $interpolate(imgSrcTemplate),
            amazonAdSystemUrlFn = $interpolate(amazonAdSystemUrlTemplate),
            iframeSrcFn = $interpolate(iframeSrcTemplate),
            affiliateId1 = "eagleaffiliateid1-20",
            affiliateId2 = "eagleaffiliateid2-20",
            affiliateId3 = "eagleaffiliateid3-20";

        // as "href" is required, we can assume if we don't have one, it's a new link.
        this.isNew = !$link;
        this.requireUpdate = false;

        // copy the link so that modifications are only persisted on submit.
        this.link = this.isNew ? {display: true} : angular.copy($link);

        /**
         * @ngDoc method
         * @name trustUrl
         * @methodOf AmazonLinkDialogCtrl
         * @param url {String} the url to trust.
         * @returns {String} the trusted url.
         */
        this.trustUrl = function(url) {
            return $sce.trustAsResourceUrl(url);
        };

        /**
         * @ngDoc method
         * @name update
         * @methodOf AmazonLinkDialogCtrl
         * @description
         */
        this.update = function() {
            this.label = this.link.label;

            this.href = hrefFn({asin: this.link.identifier, affiliateId: affiliateId1});
            this.imageHref = imageHrefFn({asin: this.link.identifier, affiliateId: affiliateId2});
            this.imgSrc = imgSrcFn({asin: this.link.identifier, affiliateId: affiliateId2});
            this.amazonAdSystemUrl = amazonAdSystemUrlFn({asin: this.link.identifier, affiliateId: affiliateId2});
            this.iframeSrc = iframeSrcFn({asin: this.link.identifier, affiliateId: affiliateId3});

            // the following variables are used to populate the textareas that have the source code in.
            this.linkSrc = '<a href="' + this.href + '" target="_blank">' + this.link.label + '</a>';
            this.imageOnlySrc= '<a rel="nofollow" href="' + this.imageHref + '" target="_blank"><img border="0" src="' +
                this.imgSrc + '" ></a><img src="' + this.amazonAdSystemUrl + '" width="1" height="1" border="0" alt="" style="border:none' +
                '!important; margin:0px !important;" />';

            this.requireUpdate = false;
        };

        if (this.link.identifier) {
            this.update();
        }
    }])

    /**
     * @ngDoc directive
     * @name amazonLinkTable
     * @description the table of amazon links, including edit/delete buttons.
     */
    .directive("amazonLinkTable", [function() {
        return {
            bindToController: true,
            controller: ["dialogs", "$uibModal", function(dialogs, $uibModal) {
                this.dirty = false;
                this.linkDialog = function(link, index) {
                    var isNew = !link,
                        modalInst = $uibModal.open({
                            bindToController: true,
                            controller: "AmazonLinkDialogCtrl",
                            controllerAs: "$ctrl",
                            size: "lg",
                            templateUrl: "templates/amazon/amazon-link-dialog.html",
                            resolve: {
                                "$link": isNew ? null : this.links[index]
                            }
                        });

                    modalInst.result
                        .then(function(link) {
                            this.links || (this.links = {});

                            return this.onUpdate({$link: link, $index: index});
                        }.bind(this))
                        .then(function(link) {
                            // NOTE: index is null if we're creating.
                            angular.isUndefined(index) ?  this.links.push(link) : this.links[index] = link;
                        }.bind(this));
                };

                this.demoteLink = function(link, $index) {
                    let removed = this.links.splice($index, 1);
                    this.links.splice($index + 1, 0, removed[0]);
                    this.dirty = true;
                };

                this.promoteLink = function(link, $index) {
                    let removed = this.links.splice($index, 1);
                    this.links.splice($index - 1, 0, removed[0]);
                    this.dirty = true;
                };

                this.removeLink = function(link, index) {
                    var dialog = dialogs.confirm("Delete Amazon Link", "Are you sure you want to delete this Amazon link?");

                    dialog.result
                        .then(function() {
                            return this.onDelete({$link: link, $index: index});
                        }.bind(this))
                        .then(function() {
                            this.links.splice(index, 1);
                        }.bind(this));
                };

                this.save = function() {
                    this.onSave({$links: this.links})
                        .then(function() {
                            this.dirty = false;
                        }.bind(this));
                };
            }],
            controllerAs: "$ctrl",
            scope: {
                "links": "=",
                "onDelete": "&",
                "onSave": "&",
                "onUpdate": "&"
            },
            templateUrl: "templates/amazon/amazon-link-table.html"
        }
    }]);
