angular.module("ngHatchTag", [])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/tags", {
                controller: "TagsCtrl",
                controllerAs: "ctrl",
                templateUrl: "templates/tag/tags.html",
                reloadOnSearch: false
            });
    }])
    .service("TagService", ["$http", "ApiBaseUri", function($http, ApiBaseUri) {
        return new function TagService() {
            this.find = function() {
                return $http.get(ApiBaseUri + "tag")
                    .then(function(response) {
                        return response.data;
                    });
            };

            this.findItems = function(tags) {
                var query = {};

                if (tags.length) {
                    query.tags = tags.join("!");
                }

                return $http.get(ApiBaseUri + "tag/items", {params: query})
                    .then(function(response) {
                        return response.data;
                    });
            };
        };
    }])
    .controller("TagsCtrl", ["$location", "$log", "$routeParams", "$scope", "TagService", function($location, $log, $routeParams, $scope, TagService) {
        this.tags = [];

        this.selectedTags = (function() {
            var tags = ($routeParams.tags || "").split("!");
            return tags[0] ? tags : [];
        })();

        function updateLocation() {
            $location.search("tags", this.selectedTags.length ? this.selectedTags.join("!"): null);

            // load the entities that match the set of selected tags.
            TagService.findItems(this.selectedTags)
                .then(function(items) {
                    this.items = items;
                }.bind(this));
        }

        this.toggleTag = function(tag) {
            var idx = this.selectedTags.indexOf(tag);
            if (idx === -1) {
                $log.debug("selected tag: " + tag);
                this.selectedTags.push(tag);

                updateLocation.call(this);
                return;
            }

            $log.debug("unselected tag: " + tag);
            this.selectedTags.splice(idx, 1);
            updateLocation.call(this);
        }.bind(this);

        // load the full set of unique tags across all taggable entities.
        TagService.find()
            .then(function(tags) {
                this.tags = tags;
            }.bind(this));

        updateLocation.call(this);
    }])
    .directive("tagItem", ["$compile", function($compile) {
        return {
            scope: {
                item: "=item"
            },
            controller: function($scope, $element) {
                var element = $compile('<div article-list-item item="item"></div>')($scope);
                $element.append(element);
            }
        }
    }]);
