angular.module("ngHatchDropdown", ["ui.bootstrap"])
    .directive("hnDropdown", [function() {
        return {
            controller: ["$scope", function($scope) {
                $scope.$selected = null;

                $scope.select = function(item) {
                    $scope.$selected = item;

                    // invoke the expression provided by the "selected" attribute.
                    $scope.selected({$item: item});
                };
            }],
            link: function(scope, element, attrs, ngModelCtrl) {
                if (ngModelCtrl) {
                    ngModelCtrl.$render = function() {
                        scope.$selected = ngModelCtrl.$viewValue;
                    };

                    if (attrs.value) {
                        scope.$watch("data", function(data) {
                            var item = data && data.find(function(item) {
                                    return item.key === ngModelCtrl.$modelValue;
                                });

                            if (item) {
                                ngModelCtrl.$setViewValue(item);
                                ngModelCtrl.$render();
                            }
                        });

                        // convert the ngModel value to the selected value.
                        ngModelCtrl.$formatters.push(function(value) {
                            var data = scope.data,
                                item;

                            // only if we actually have data and a key, can we determine if a) we have an item for the
                            // value and b) the key is valid.
                            if (data && (value !== null) && !angular.isUndefined(value)) {
                                // we have data, so lookup the item with the value.
                                item = data.find(function(item) {
                                    return item.key === value;
                                });

                                // no item? then we're invalid. Store the invalid key in scope.$invalid for display.
                                ngModelCtrl.$setValidity(null, !!item);
                                scope.$invalid = item ? null : value;
                            }

                            return item;
                        });

                        // convert selected value to the value for ngModel.
                        ngModelCtrl.$parsers.push(function(value) {
                            // only run the value expression if we actually have an object for the scope.
                            if (value) {
                                // we have a valid, so we can say we're valid. Clear scope.$invalid to update the
                                // display as necessary.
                                ngModelCtrl.$setValidity(null, true);
                                scope.$invalid = null;

                                // resolve the value for the item to store in the model.
                                return scope.value({$item: value});
                            }

                            return null;
                        });
                    }

                    scope.$watch("$selected", function(newVal) {
                        ngModelCtrl.$setViewValue(newVal);
                    });
                }
            },
            require: "?ngModel",
            scope: {
                "data": "=",
                "label": "&",
                "value": "&",
                "selected": "&"
            },
            templateUrl: "templates/hn/hn-dropdown.html"
        };
    }]);