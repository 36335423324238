//angular.module("hnConfig", ["ngHatchUtils"])
angular.module("hnConfig", [])
    /**
     * @ngDoc service
     * @name ConfigService
     * @description
     */
    .service("ConfigService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        this.load = function(key) {
            return $http.get(ApiBaseUri + "config/" + key, {params: {rest:false}})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.save = function(key, props) {
            return $http.put(ApiBaseUri + "config/" + key, props)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };
    }]);
