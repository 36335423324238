"use strict";

angular.module("ngHatchExhibition", ["rt.debounce", "dialogs.main", "hnAmazon", "hnExternalLink", "ngHatchArtist", "ngHatchCategory", "ngHatchDropdown", "ngHatchMuseum", "ngHatchUtils", "ngRoute", "ngTagsInput", "uiSwitch", "ui.bootstrap", "ui.select", "uiwDatePicker", "uiwFuzzyDate", "uiw-rating", "uiw-sort-control", "uiwidgets-ng-tpls"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/exhibitions/create", {
                controller: "ExhibitionCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_CREATE_EXHIBITION",
                templateUrl: "templates/exhibition/edit-exhibition.html"
            })
            .when("/admin/exhibitions/:id", {
                controller: "ExhibitionCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/exhibition/view-exhibition.html"
            })
            .when("/admin/exhibitions/:id/edit", {
                controller: "ExhibitionCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_UPDATE_EXHIBITION",
                templateUrl: "templates/exhibition/edit-exhibition.html"
            })
            .when("/admin/exhibitions", {
                controller: "ExhibitionsCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_ADMIN",
                reloadOnSearch: false,
                templateUrl: "templates/exhibition/exhibitions.html"
            });
    }])
    .service("ExhibitionIndexes", [function() {
        var indexes = [
            {key: "title", label: "Title"}
        ];

        this.find = function() {
            return indexes;
        };

        this.lookup = function(i) {
            var index = indexes[0];

            if (i) {
                index = indexes.find(function(index) {
                    return index.key === i;
                });
            }

            return index;
        };
    }])
    .service("ExhibitionService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        this.find = function(opts) {
            const index = opts.index || "title";

            // transform the incoming find options into the form required on the url in order to send them with
            // the request.
            const queryOpts = {};

            // transform the incoming find options into the form required on the url in order to send them with
            // the request.
            opts.filter && (queryOpts.q = $ngHatchUtils.getFilterQueryParam(index, opts.filter));
            opts.offset && (queryOpts.offset = opts.offset);
            angular.isUndefined(opts.limit) || (queryOpts.limit = opts.limit);
            opts.sort && (queryOpts.sort = opts.sort);
            opts.fields && (queryOpts.fields = Array.isArray(opts.fields)
                ? opts.fields.join(",")
                : opts.fields);

            // transfer any facets to http parameters on the url.
            angular.forEach(opts.facets, function(value, facet) {
                queryOpts[facet] = value;
            });

            // build up the query parameters from the options object.
            angular.forEach(opts.where, function(val, prop) {
                if (val.$in) {
                    queryOpts[prop] = val.$in;
                } else {
                    queryOpts[prop] = val;
                }
            });

            return $http.get(ApiBaseUri + "exhibition/", {params: queryOpts})
                .then(function(response) {
                    return response.data;
                })
                .then(function(data) {
                    angular.forEach(data.results, function(exhibition) {
                        exhibition.createdDate = new Date(exhibition.createdDate);
                        exhibition.lastModifiedDate = new Date(exhibition.lastModifiedDate);
                        if (exhibition.events) {
                            angular.forEach(exhibition.events, function(event) {
                                $ngHatchUtils.parseFuzzyDate(event.startDate);
                                $ngHatchUtils.parseFuzzyDate(event.endDate);
                            });
                        }
                    });
                    return data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.load = function(id) {
            return $http.get(ApiBaseUri + "exhibition/" + id)
                .then(function(response) {
                    return response.data;
                })
                .then(function(exhibition) {
                    exhibition.createdDate = new Date(exhibition.createdDate);
                    exhibition.lastModifiedDate = new Date(exhibition.lastModifiedDate);
                    if (exhibition.events) {
                        angular.forEach(exhibition.events, function(event) {
                            $ngHatchUtils.parseFuzzyDate(event.startDate);
                            $ngHatchUtils.parseFuzzyDate(event.endDate);
                        });
                    }
                    return exhibition;
                });
        };

        this.remove = function(id) {
            return $http.delete(ApiBaseUri + "exhibition/" + id);
        };

        this.save = function(exhibition) {
            var method = exhibition._id ? "put" : "post";
            return $http({
                    method: method,
                    url: ApiBaseUri + "exhibition/" + (exhibition._id || ""),
                    data: exhibition
                }).then(function(response) {
                    return response.data;
                });
        };

        this.deleteAmazonLink = function(exhibitionId, linkId) {
            return $http({
                method: "delete",
                url: ApiBaseUri + "exhibition/" + exhibitionId + "/amazon-links/" + linkId
            }).then(function(response) {
                return response.data;
            })
        };

        this.saveAmazonLink = function(exhibitionId, link) {
            return $http({
                method: "post",
                url: ApiBaseUri + "exhibition/" + exhibitionId + "/amazon-links",
                data: link
            }).then(function(response) {
               return response.data;
            });
        };

        this.setAmazonLinks = function(exhibitionId, links) {
            return $http({
                method: "put",
                url: ApiBaseUri + "exhibition/" + exhibitionId + "/amazon-links",
                data: links
            }).then(function(response) {
               return response.data;
            });
        };

        this.deleteEvent = function(exhibitionId, eventId) {
            return $http({
                method: "delete",
                url: ApiBaseUri + "exhibition/" + exhibitionId + "/events/" + eventId
            }).then(function(response) {
                return response.data;
            });
        };

        this.saveEvent = function(exhibitionId, event) {
            return $http({
                    method: "post",
                    url: ApiBaseUri + "exhibition/" + exhibitionId + "/events",
                    data: event
                }).then(function(response) {
                    return response.data;
                }).then(function(event) {
                    $ngHatchUtils.parseFuzzyDate(event.startDate);
                    $ngHatchUtils.parseFuzzyDate(event.endDate);
                    return event;
                });
        };

        this.deleteExternalLink = function(exhibitionId, linkId) {
            return $http({
                method: "delete",
                url: ApiBaseUri + "exhibition/" + exhibitionId + "/external-links/" + linkId
            }).then(function(response) {
                return response.data;
            })
        };

        this.saveExternalLink = function(exhibitionId, link) {
            return $http({
                method: "post",
                url: ApiBaseUri + "exhibition/" + exhibitionId + "/external-links",
                data: link
            }).then(function(response) {
                return response.data;
            });
        };

        /**
         * @ngDoc method
         * @name resolveCategories
         * @param exhibition {Object} the exhibition model.
         * @returns {Array} an array of category paths for the model.
         * @description transform how the categories are stored on an exhibition into a set of category paths.
         * TODO: normalise how categories are stored on the exhibition and remove this.
         */
        this.resolveCategories = function(exhibition) {
            var keys = [], key;

            if (!exhibition || !exhibition.categories) {
                return keys;
            }

            function pathFrom(categoryKey, subCategoryKey) {
                var path = [];
                if (exhibition.categories[categoryKey]) {
                    path.push(categoryKey);
                    path.push(exhibition.categories[categoryKey]);
                    if (exhibition.categories[subCategoryKey]) {
                        path.push(exhibition.categories[subCategoryKey]);
                    }
                    return path;
                }

                return null;
            }

            key = pathFrom("origin", "subOrigin");
            key && keys.push(key);
            key = pathFrom("medium", "subMedium");
            key && keys.push(key);
            key = pathFrom("subject", "subSubject");
            key && keys.push(key);
            key = pathFrom("period", "subPeriod");
            key && keys.push(key);

            return keys;
        };
    }])

    /**
     * @ngDoc controller
     * @name ExhibitionCtrl
     * @description the controller for the edit/create Exhibition screen.
     */
    .controller("ExhibitionCtrl", ["$hnDirtyNag", "$hnFormErrorMessage", "$log", "$ngHatchState", "$q", "$routeParams", "$scope", "$timeout", "$uibModal", "ArtistService", "CategoryService", "ExhibitionService", "MediaService", "MuseumService", "dialogs", "hnPatterns", "hnPermission", function($hnDirtyNag, $hnFormErrorMessage, $log, $ngHatchState, $q, $routeParams, $scope, $timeout, $uibModal, ArtistService, CategoryService, ExhibitionService, MediaService, MuseumService, dialogs, hnPatterns,  hnPermission) {
        var initPromises = [],
            stopNag;

        $scope.patterns = hnPatterns;
        $scope.alerts = [];

        // flag to determine if we're creating an exhibition.
        this.isNew = !$routeParams.id;
        this.isSaving = false;

        this.canPublish = hnPermission.all("CAN_PUBLISH_EXHIBITION");

        // setup the default model.
        this.exhibition = {
            body: "",
            tags: [],
            events: [],
            operatorNotes: []
        };

        // of hash of the categories / sub-categories. Used to feed the drop-downs.
        this.categories = {};
        this.subCategories = {};

        // the set of "tags" to display. These are derived from categories.
        this.tags = [];

        // load all of the categories for the drop-downs.
        initPromises.push(CategoryService.load()
            .then(function(categories) {
                this.categoriesRaw = categories;

                // TODO: stop doing this and use categoriesRaw.
                // create a hash of top-level categories.
                this.categories = {};
                angular.forEach(categories, function(val) {
                    this.categories[val.key] = val;
                }.bind(this));
            }.bind(this)));

        // if we were given an id on the url, load the record and populate the model.
        if (!this.isNew) {
            initPromises.push(ExhibitionService.load($routeParams.id)
                .then(function(exhibition) {
                    // merge the loaded exhibition with the defaults and assign that to the controller, this is
                    // important for the operatorNotes because the template is referencing index 0.
                    this.exhibition = angular.extend(this.exhibition, exhibition);

                    // if we've got events, we need to lookup the museum names...
                    // TODO: this feels a little bit icky, consider doing this at the backend.
                    if (exhibition.events && exhibition.events.length) {
                        var museumIds = Object.keys(exhibition.events.reduce(function(result, event) {
                                event.museumId && (result[event.museumId] = true);
                                return result;
                            }, {}));

                        // if there are no museums to lookup, abort early.
                        if (!museumIds.length) {
                            return;
                        }

                        // lookup the museums and substitute in the museum names into the events.
                        MuseumService.find({where: {_id: {$in: museumIds}}, limit: 0})
                            .then(function(response) {
                                var museumsNames = response.results.reduce(function(hash, museum) {
                                        hash[museum._id] = museum.name;
                                        return hash;
                                    }, {});

                                angular.forEach(exhibition.events, function(event) {
                                    event.museumName = museumsNames[event.museumId];
                                });
                            });
                    }
                }.bind(this)));
        }

        // if we're looking at the exhibition from a museum, pre-populate the museumId in the first event...
        if ($routeParams.museumId) {
            this.museumId = $routeParams.museumId;
            if (!$routeParams.id) {
                this.exhibition.events[0] = {museumId: this.museumId};
            }
        }

        // when the categories and the exhibition have loaded, we need to do a quick pass through to setup any
        // sub-category lists that should be visible from the currently selected top-level categories.
        $q.all(initPromises)
            .then(function() {
                // now all the data has loaded, if we have a form, register a $locationChangeStart handler to warn
                // the user if they attempt to navigate away while the form is dirty.
                if ($scope.form) {
                    // wrap in a timeout to allow time for the form state to settle...
                    $timeout(function() {
                        stopNag = $hnDirtyNag(function() {
                            // we need to check for the existence of the form again, as it may have been destroyed.
                            return $scope.form && $scope.form.$dirty;
                        });
                    });
                }

                // populate the rows for the sub-category drop-downs on any selected top-level category.
                angular.forEach(this.exhibition.categories, function(val, key) {
                    var topCategory = this.categories[key],
                        category = topCategory && this.getSubCategory(topCategory.subs, val);

                    this.subCategories[key] = category && category.subs ? category :  null;
                }.bind(this));

                // build the set of tags for the page.
                function buildTags(exhibition, categories) {
                    var tags = [],
                        categoryPaths = ExhibitionService.resolveCategories(exhibition),
                        flatCategories = CategoryService.flatten(categoryPaths, categories);

                    // add tags for "categories"
                    tags = tags.concat(flatCategories.map(function(item) {
                        return {label: item.label, type: "category"};
                    }));

                    // add tags for "artists"
                    angular.forEach(exhibition.artists, function(artist) {
                        tags.push({label: artist.fullName, type: "artist"});
                    });

                    return tags;
                }

                this.tags = buildTags(this.exhibition, this.categoriesRaw);
            }.bind(this));

        this.toolbar = [['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
            ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
            ['html', 'insertImage','insertLink', 'insertVideo', 'uploadImage']];

        /**
         * @ngDoc method
         * @name eventDialog
         * @methodOf ExhibitionCtrl
         * @param [event] {Object} the event object to edit.
         * @param [index] {Number} the index of the event in the set of events.
         */
        this.eventDialog = function(event, index) {
            var isNew = !event,
                modalInst = $uibModal.open({
                    controller: "ExhibitionEventDialogCtrl",
                    controllerAs: "ctrl",
                    templateUrl: "templates/exhibition/event-dialog.html",
                    resolve: {
                        "$event": event,
                        "$exhibition": null,
                        "$museum": null
                    }
                });

            modalInst.result
                .then(function(event) {
                    this.exhibition.events || (this.exhibition.events = {});
                    ExhibitionService.saveEvent(this.exhibition._id, event)
                        .then(function(event) {
                            isNew ?  this.exhibition.events.push(event) : this.exhibition.events[index] = event;
                        }.bind(this));
                }.bind(this));
        };

        /**
         * @ngDoc method
         * @name cancel
         * @methodOf ExhibitionCtrl
         * @description cancel editing the record. All changes will be lost and the user will be returned to the
         * Exhibitions list.
         */
        this.cancel = function(forceList) {
            var url;

            if ($scope.form && $scope.form.$dirty) {
                $log.debug("TODO: confirm");
            }

            if (this.museumId) {
                $ngHatchState.location("/admin/museums/" + this.museumId);
                return;
            }

            url = !forceList && this.exhibition._id ? "/admin/exhibitions/" + this.exhibition._id : "/admin/exhibitions";
            $ngHatchState.location(url);
        }.bind(this);

        /**
         * @ngDoc method
         * @name getSubCategory
         * @param category {Array} an array of category objects.
         * @param subKey {String} the key of the category we're looking for.
         * @returns {Object} the desired category object or undefined.
         * @description lookup a sub-category in an array of category objects. Used by the ng-change on the top-level
         * categories to hide/populate and show the sub-categories.
         */
        this.getSubCategory = function(category, subKey) {
            return category && category.find(function(item) {
                return item.key === subKey;
            });
        };

        /**
         * Update a sub category. This is called by the UI when a category is selected. It is responsible for setting
         * the appropriate sub-category in the model so that the sub-category dropdown is either shown/hidden. If the
         * category does not have any sub-categories, the key for the sub-category on the model is deleted.
         *
         * @param key {String} the category key.
         * @param subKey {String} the sub-category key.
         * @returns {Object} the sub-category object.
         */
        this.updateSubCategory = function(key, subKey) {
            var subCategory = this.getSubCategory(this.categories[key].subs, this.exhibition.categories[key]);

            // update the subCategories collection in the model to show/hide the sub-category dropdown.
            this.subCategories[key] = subCategory;

            // if there is no category, or there are no subs for this category, clear any value in the model we
            // might have for that sub-category.
            if (!subCategory || !subCategory.subs) {
                delete this.exhibition.categories[subKey];
            }

            return subCategory;
        };

        /**
         * @ngDoc method
         * @name mediaList
         * @methodOf ExhibitionCtrl
         * @returns {Promise} a promise for the media list.
         * @description get the media list for the exhibitions record.
         */
        this.mediaList = function() {
            var imageIds = this.exhibition.images || [],
                results = [];

            if (imageIds.length) {
                // filter the imageIDs, so we don't pass undefined or null values to the MediaService. NOTE: any gaps
                // (where there are no imageIds, or there was no image are preserved later in the process.
                let filteredImageIds = imageIds.filter(function(imageId) {
                        return !!imageId;
                    });

                results = MediaService.find({where: {"_id": {"$in": filteredImageIds}}})
                    .then(function(items) {
                        // for all items returned, populate the "uri" and where appropriate, the "thumbUri" properties,
                        // at the same time, produce a hash of returned image data, keyed by image ID.
                        items = items.reduce(function(items, item) {
                            item.uri = MediaService.link(item.id);
                            if (item.thumb) {
                                item.thumbUri = MediaService.thumbLink(item.id);
                            }

                            items[item.id] = item;
                            return items;
                        }, {});

                        // use the list of images we asked for to generate an ordered list of image data, where images
                        // were not returned (i.e. missing in the database), enter a blank record, containing only the
                        // missing id.
                        items = imageIds.map(function(imageId) {
                            return items[imageId] || {id: imageId};
                        });

                        return items;
                    });
            }

            return $q.when(results);
        };

        /**
         * @ngDoc method
         * @name onDeleteAmazonLink
         * @methodOf ExhibitionCtrl
         * @param link {Object} the link object to remove.
         * @param index {Number} the index of the link in the list.
         * @return {Promise} a promise resolved when the amazon link is deleted.
         */
        this.onDeleteAmazonLink = function(link, index) {
            return ExhibitionService.deleteAmazonLink(this.exhibition._id, link._id);
        };

        /**
         * @ngDoc method
         * @name onDeleteExternalLink
         * @methodOf ExhibitionCtrl
         * @param link {Object} the link object to remove.
         * @param index {Number} the index of the link in the list.
         * @return {Promise} a promise resolved when the external link is deleted.
         */
        this.onDeleteExternalLink = function(link, index) {
            return ExhibitionService.deleteExternalLink(this.exhibition._id, link._id);
        };

        /**
         * @ngDoc method
         * @name onSaveAmazonLinks
         * @methodOf ExhibitionCtrl
         * @param links {Array} an array of amazon link objects.
         * @return {Promise} a promise for the saved set of amazon links.
         * @description save a complete set of amazon links for the exhibition.
         */
        this.onSaveAmazonLinks = function(links) {
            return ExhibitionService.setAmazonLinks(this.exhibition._id, links);
        };

        /**
         * @ngDoc method
         * @name onUpdateAmazonLink
         * @methodOf ExhibitionCtrl
         * @param [link] {Object} the link object to edit.
         * @param [index] {Number} the index of the link in the set of links.
         * @return {Promise} a promise for the updated/created link object.
         * @description create/update an amazon link.
         */
        this.onUpdateAmazonLink = function(link, index) {
            return ExhibitionService.saveAmazonLink(this.exhibition._id, link);
        };

        /**
         * @ngDoc method
         * @name onUpdateExternalLink
         * @methodOf ExhibitionCtrl
         * @param [link] {Object} the link object to edit.
         * @param [index] {Number} the index of the link in the set of links.
         * @return {Promise} a promise for the updated/created link object.
         * @description create/update an external link.
         */
        this.onUpdateExternalLink = function(link, index) {
            return ExhibitionService.saveExternalLink(this.exhibition._id, link);
        };

        /**
         * @ngDoc method
         * @name onIngestComplete
         * @methodOf ExhibitionCtrl
         * @param mediaItem {Object} the ingested media item.
         * @description triggered when an image is uploaded via the rich text editor.
         */
        this.onIngestComplete = function(mediaItem) {
            // add the image id to the list of images bound to this record.
            var images = this.exhibition.images || (this.exhibition.images = []);
            images.push(mediaItem.id);
        };

        /**
         * @ngDoc method
         * @name onInsertMedia
         * @param mediaItem {Object} the inserted media item
         * @description triggered when an image is inserted via the insert media modal.
         */
        this.onInsertMedia = function(mediaItem) {
            // add the image id to the list of images bound to this record.
            var images = this.exhibition.images || (this.exhibition.images = []),
                idx = images.indexOf(mediaItem.id);

            if (idx === -1) {
                images.push(mediaItem.id);
            }
        };

        /**
         * @ngDoc method
         * @name onUnlinkMedia
         * @param mediaItem {Object} the selected media item
         * @description triggered when an image in unlinked from an exhibition.
         */
        this.onUnlinkMedia = function(mediaItem) {
            var images = this.exhibition.images || [],
                idx = images.indexOf(mediaItem.id);

            if (idx !== -1) {
                images.splice(idx, 1);
            }
        };

        this.museums = [];
        this.refreshMuseums = function(search) {
            MuseumService.find({"filter": search})
                .then(function(data) {
                    this.museums = data.results;
                }.bind(this));
        }.bind(this);

        this.loadArtists = function(query) {
            return ArtistService.find({"filter": query})
                .then(function(response) {
                    return response.results;
                }.bind(this));
        };

        /**
         * @ngDoc method
         * @name save
         * @methodOf ExhibitionCtrl
         * @param museum {Object} the Exhibition model.
         * @description save the Exhibition model.
         */
        this.save = function(exhibition) {
            var isNew = !exhibition._id;

            // if already saving, abort.
            if (this.isSaving) {
                return;
            }

            // copy the model so that any mutations made for transport are not reflected in the UI.
            exhibition = angular.copy(exhibition);

            // flatten exhibition.tags because of ngTagInput limitations.
            exhibition.tags = (exhibition.tags || []).map(function(item) {
                return item.text;
            });


            if (isNew) {
                // if the event has a museum object, rather than a museum id, we'll make that correction here before saving.
                if (exhibition.events && exhibition.events[0] && exhibition.events[0].museum) {
                    exhibition.events[0].museumId = exhibition.events[0].museum._id;
                    delete exhibition.events[0].museum;
                }
            } else {
                // editing a record, so we need to detach the link entities to prevent trashing them.
                delete exhibition.amazonLinks;
                delete exhibition.externalLinks;
                delete exhibition.events;
            }

            // if the form is not valid, don't send the save request...
            if ($scope.form.$invalid) {
                this.alerts = [{type: "danger", "message": $hnFormErrorMessage($scope.form)}];
                return;
            }

            // if the lastModifiedDate has not been changed, then update the lastModifiedDate to now.
            if (!$scope.form.lastModifiedDate.$dirty) {
                exhibition.lastModifiedDate = Date.now();
            }

            this.isSaving = true;
            ExhibitionService.save(exhibition)
                .then(function(exhibition) {
                    // stop the location listener to prevent the nag when the intention is to save.
                    stopNag && stopNag();

                    if (this.museumId) {
                        $ngHatchState.location("/admin/museums/" + this.museumId);
                        return;
                    }
                    $ngHatchState.location("/admin/exhibitions/" + exhibition._id);
                }.bind(this))
                .catch(function(response) {
                    $log.error(response);
                    $scope.alerts.push("failed to save Exhibition");
                })
                .finally(function() {
                    this.isSaving = false;
                }.bind(this));
        };

        /**
         * @ngDoc method
         * @name removeEvent
         * @methodOf ExhibitionCtrl
         * @param event {Object} the event object to remove.
         * @param index {Number} the index of the event in the list.
         */
        this.removeEvent = function(event, index) {
            var dialog = dialogs.confirm("Delete Event", "Are you sure you want to delete this event?");

            dialog.result
                .then(function() {
                    return ExhibitionService.deleteEvent(this.exhibition._id, event._id);
                }.bind(this))
                .then(function() {
                    this.exhibition.events.splice(index, 1);
                }.bind(this));
        };

    }])
    .controller("ExhibitionsCtrl", ["$location", "$log", "$ngHatchState", "$scope", "ExhibitionIndexes", "ExhibitionService", "debounce", "dialogs", function($location, $log, $ngHatchState, $scope, ExhibitionIndexes, ExhibitionService, debounce, dialogs) {
        this.error = null;
        this.loading = false;

        this.exhibitions = [];

        // figure out the correct state for the page. The state may be obtained from the state store if it had
        // previously been stored, or derived from the url
        this.state = angular.extend({page: 1, q: ""}, $location.search());

        // derive the pageSize from the state, or use a default.
        this.pageSize = this.state.l || 10;

        // get a list of indexes available.
        this.indexes = ExhibitionIndexes.find();

        // update the index property used for display.
        this.index = ExhibitionIndexes.lookup(this.state.i);

        // extract any facets from the url into the facets object.
        this.facets = {};
        angular.forEach(this.state, function(value, key) {
            if (key.substr(0, 3) === "fa_") {
                this.facets[key] = value;
            }
        }.bind(this));

        // set an artificial total in the interim, or the paginator will reset the page to 1.
        this.total = this.pageSize * this.state.page;

        // react to the url changing. Update the state model and hit refresh to fetch the data.
        $scope.$on("$routeUpdate", function() {
            this.state = $location.search();
            this.refresh();
        }.bind(this));

        this.refresh = function() {
            var opts = {
                    filter: this.state.q,
                    index: this.state.i,
                    offset: (this.state.page - 1) * this.pageSize,
                    limit: this.pageSize,
                    sort: this.state.sort,
                    facets: this.facets
                };

            ExhibitionService.find(opts)
                .then(function(data) {
                    this.exhibitions = data.results;
                    this.total = data.total;
                }.bind(this))
                .catch(function(err) {
                    $log.warn("failed to load data", err);
                    this.error = err.message;
                }.bind(this))
                .finally(function() {
                    this.loading = false;
                }.bind(this));
        };

        this.remove = function(exhibition) {
            var dialog = dialogs.confirm("Delete Exhibition", "Are you sure you want to delete this Exhibition?"),
                self = this;

            dialog.result
                .then(function() {
                    ExhibitionService.remove(exhibition._id)
                        .then(function() {
                            self.refresh();
                        }.bind(this))
                            .catch(function() {
                                // TODO: handle failure to remove record.
                            });
                })
        }.bind(this);

        /**
         * @ngDoc method
         * @name storeState
         * @methodOf MuseumsCtrl
         * @description store the current page state in the store. This is invoked prior to navigation to pages where
         * we may want to return form. When using with anchor tags, calling this via ng-click will have the state stored
         * before the browser location is updated.
         */
        this.storeState = function() {
            $ngHatchState.location();
        }.bind(this);

        this.filter = debounce(120, function() {
            $location.search(this.state);
        }.bind(this));

        this.pageChange = function() {
            $location.search(this.state);
        }.bind(this);

        this.changeIndex = function(index) {
            this.index = index;
            this.state.i = index.key;

            // only re-search if there is query text.
            if (this.state.q) {
                $location.search(this.state);
            }
        }.bind(this);

        this.changeFacets = function(index) {
            $location.search(this.state);
        }.bind(this);

        /**
         * @ngDoc method
         * @name changePageSize
         * @methodOf MuseumsCtrl
         * @description invoked when the page size is changed. Used to manipulate the state object, by setting
         * or deleting the "l" property. After the state has been updated, the url updated.
         */
        this.changePageSize = function() {
            if (this.pageSize !== 10) {
                this.state.l = this.pageSize;
            } else {
                delete this.state.l;
            }
            $location.search(this.state);
        }.bind(this);

        // update the search with the new sort order.
        this.sortBy = function() {
            $location.search(this.state);
        };

        this.refresh();
    }])

    /**
     * @ngDoc controller
     * @name ExhibitionEventDialogCtrl
     */
    .controller("ExhibitionEventDialogCtrl", ["$event", "$exhibition", "$museum", "$scope", "MuseumService", "hnPatterns", function($event, $exhibition, $museum, $scope, MuseumService, hnPatterns) {
        this.event = $event ? angular.copy($event) : {};
        this.museumReadOnly = !!$museum;

        $scope.patterns = hnPatterns;

        // if we were given an exhibition, copy the id into the event record.
        if ($exhibition) {
            this.exhibition = $exhibition;

            this.event.exhibitionId = $exhibition._id;
            this.event.exhibitionTitle = $exhibition.title;
        }

        this.fuzzyEnd = false;
        this.fuzzyStart = false;

        this.refreshMuseums = function(search) {
            MuseumService.find({"filter": search})
                .then(function(data) {
                    this.museums = data.results;
                }.bind(this))
        }.bind(this);

        this.updateMuseum = function(museum) {
            this.museum = museum;

            this.event.museumId = museum._id;
            this.event.museumName = museum.name;
        };

        // if the dialog was created with prior knowledge of the museum, then we assume we're adding an event from the
        // context of a museum. This enables us to assume the museumId and not display the location autocomplete...
        if ($museum) {
            this.updateMuseum($museum);
        } else {
            // if we're editing an existing event, we'll need to load the current museum record for the drop-down.
            if (this.event.museumId) {
                MuseumService.find({"where": {"_id": this.event.museumId}})
                    .then(function(data) {
                        this.updateMuseum(data.results[0]);
                    }.bind(this));
            }

            this.museums = [];
        }

        this.save = function($close) {
            if ($scope.form.$invalid) {
                return;
            }

            $close(this.event);
        }.bind(this);
    }])
    .controller("ExhibitionListItemCtrl", ["$scope", function($scope) {
        // TODO: any custom logic for interaction with the list item.
    }])
    .directive("exhibitionListItem", [function() {
        return {
            scope: {
                item: "=item"
            },
            controller: "ExhibitionListItemCtrl",
            templateUrl: "templates//list-item-exhibition.html"
        };
    }]);
