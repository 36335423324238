angular.module("hnAuth", ["hnConfig", "satellizer"])
    .config(["$authProvider", "ApiBaseUri", function($authProvider, ApiBaseUri) {
        $authProvider.baseUrl = ApiBaseUri;

        // Add Authorization header to HTTP request
        $authProvider.httpInterceptor = true;
    }])
    .run(["$auth", "$location", "$rootScope", "hnPermission", function($auth, $location, $rootScope, hnPermission) {
        var isAuthenticated, payload;

        // seed the "isAuthenticated" variable in the root scope from the auth library.
        $rootScope.isAuthenticated = isAuthenticated = $auth.isAuthenticated();

        // seed the "user" object in the root scope, if we're authenticated.
        $rootScope.user = null;
        if (isAuthenticated) {
            payload = $auth.getPayload();

            $rootScope.user = (payload && payload.user) || null;
        }

        // listen for $routeChangeStart to implement the security policy on route changes.
        $rootScope.$on("$routeChangeStart", function(ev, next) {
            var permissions = next
                    ? angular.isString(next.permissions) ? [next.permissions] : next.permissions
                    : [],
                user = $rootScope.user;

            // no permissions required, short circuit.
            if (!permissions) {
                return;
            }

            // required permissions and no user object?
            if (!user) {
                // redirect to login page.
                // TODO: this should really be part of the hnAuth module config
                $location.path("/login");
                return;
            }

            if (!hnPermission.all(permissions)) {
                // TODO: this should really be part of the hnAuth module config
                $location.path("/unauthorised");
                return;
            }
        });
    }])

    /**
     * @ngDoc service
     * @name hnAuth
     * @description the authorization service for managing the process of logging in and logging out.
     */
    .service("hnAuth", ["$auth", "$log", "$q", "$rootScope", "SatellizerConfig", "SatellizerShared", "SatellizerStorage", function($auth, $log, $q, $rootScope, SatellizerConfig, SatellizerShared, SatellizerStorage) {
        /**
         * @ngDoc method
         * @name login
         * @methodOf hnAuth
         * @param credentials {Object}
         * @returns {Promise} a promise for the logged in user object.
         */
        this.login = function(credentials) {
            return $auth.login(credentials)
                .then(function() {
                    $rootScope.isAuthenticated = $auth.isAuthenticated();
                    $rootScope.user = $auth.getPayload().user;

                    $log.debug("{isAuthenticated: " + $rootScope.isAuthenticated + ", user: " + $rootScope.user + "}");

                    // emit a "login" event.
                    $rootScope.$emit("login");

                    $rootScope.$emit("authChange");

                    return $rootScope.user;
                });
        };

        /**
         * @ngDoc method
         * @name logout
         * @methodOf hnAuth
         * @returns {Promise} a promise that is resolved when the user is logged out.
         */
        this.logout = function() {
            $auth.logout();

            $rootScope.isAuthenticated = false;
            $rootScope.user = null;

            $rootScope.$emit("authChange");

            return $q.resolve();
        };

        /**
         * @ngDoc method
         * @name applyAuthHeader
         * @methodOf hnAuth
         * @param fn {Function} a strategy for applying the auth header to a request.
         * @description Apply a strategy for setting the authentication header on a request. The strategy function takes
         * 2 arguments, a header name and value.
         */
        this.applyAuthHeader = function(fn) {
            var headerName = SatellizerConfig.authHeader, token, tokenName;

            if (fn && headerName && SatellizerShared.isAuthenticated()) {
                tokenName = SatellizerConfig.tokenPrefix ? SatellizerConfig.tokenPrefix + '_' + SatellizerConfig.tokenName : SatellizerConfig.tokenName;
                token = SatellizerStorage.get(tokenName);

                if (token) {
                    if (SatellizerConfig.authToken) {
                        token = SatellizerConfig.authToken + ' ' + token;
                    }

                    fn(headerName, token);
                }
            }
        };
    }])

    /**
     * @ngDoc service
     * @name hnPermission
     * @description a service the implements checking permissions against the currently logged in user.
     */
    .service("hnPermission", ["$log", "$rootScope", function($log, $rootScope) {
        /**
         * @ngDoc method
         * @name oneOf
         * @methodOf hnPermission
         * @param permissions {Array} an array permission strings.
         * @returns {boolean} the boolean decision.
         */
        this.oneOf = function(permissions) {
            var user = $rootScope.user;

            // short circuit if no permissions have been requested....
            if (!permissions) {
                return true;
            }

            // permissions but no user object?
            if (!user) {
                return false;
            }

            const userPermissions = user.permissions || [];
            Array.isArray(permissions) || (permissions = [permissions]);

            // check user.permissions has all the required permissions.
            $log.debug("requireOneOfPermission: {" + permissions.join(", ") + "}, userPermissions: {" + userPermissions.join(", ") + "}");

            // redirect to unauthorised page if we fail the check.
            for(let f=0, l=permissions.length; f<l; f++) {
                if (userPermissions.indexOf(permissions[f]) === -1) {
                    // matched one! we're done.
                    return true;
                    // missing AT LEAST one of the the required permissions. redirect to the unauthorized page.
                    $log.debug("missing required permission: " + permissions[f]);
                    return false;
                }
            }

            $log.debug("failed to match one of permissions: " + permissions.join(", "));

            // if we got here, we failed to match a permission.
            return false;
        };

        /**
         * @ngDoc method
         * @name all
         * @methodOf hnPermission
         * @param permissions {Array} an array permission strings.
         * @returns {boolean} the boolean decision.
         */
        this.all = function(permissions) {
            var user = $rootScope.user,
                f, l;

            // short circuit if no permissions have been requested....
            if (!permissions) {
                return true;
            }

            // permissions but no user object?
            if (!user) {
                return false;
            }

            const userPermissions = user.permissions || [];
            Array.isArray(permissions) || (permissions = [permissions]);

            // check user.permissions has all the required permissions.
            $log.debug("requiredAllPermissions: {" + permissions.join(", ") + "}, userPermissions: {" + userPermissions.join(", ") + "}");

            // redirect to unauthorised page if we fail the check.
            for(f=0, l=permissions.length; f<l; f++) {
                if (userPermissions.indexOf(permissions[f]) === -1) {
                    // missing AT LEAST one of the the required permissions. redirect to the unauthorized page.
                    $log.debug("missing required permission: " + permissions[f]);
                    return false;
                }
            }

            // if we got here, then we're good to go...
            return true;
        };
    }])

    /**
     * @ngDoc directive
     * @name hnPermission
     * @description show/hide an element depending on permission checks.
     */
    .directive("hnPermission", ["$interpolate", "$rootScope", "hnPermission", function($interpolate, $rootScope, hnPermission) {
        return {
            link: function(scope, element, attrs) {
                var permString = attrs["hnPermission"],
                    permissions;

                if (permString) {
                    permissions = $interpolate(permString)(scope);
                }

                permissions = permissions.split(/\s+/);

                function applyPermissions() {
                    var isPermitted = hnPermission.all(permissions);
                    element.toggleClass("hn-auth-granted", isPermitted);
                }

                // apply the permissions immediately.
                applyPermissions();

                // listen for changes in the authentication state and adjust the permissions as appropriate.
                $rootScope.$on("authChange", applyPermissions);
            }
        };
    }])

    /**
     * @ngDoc directive
     * @name hnPermissionOneOf
     * @description show/hide an element depending on permission checks.
     */
    .directive("hnPermissionOneOf", ["$interpolate", "$rootScope", "hnPermission", function($interpolate, $rootScope, hnPermission) {
        return {
            link: function(scope, element, attrs) {
                var permString = attrs["hnPermissionOneOf"],
                    permissions;

                if (permString) {
                    permissions = $interpolate(permString)(scope);
                }

                permissions = permissions.split(/\s+/);

                function applyPermissions() {
                    var isPermitted = hnPermission.oneOf(permissions);
                    element.toggleClass("hn-auth-granted", isPermitted);
                }

                // apply the permissions immediately.
                applyPermissions();

                // listen for changes in the authentication state and adjust the permissions as appropriate.
                $rootScope.$on("authChange", applyPermissions);
            }
        };
    }]);
