angular.module("ngSlug", ["dialogs.main", "rt.debounce", "uiSwitch"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/slug/create", {
                controller: "SlugCtrl",
                controllerAs: "$ctrl",
                permissions: "CAN_CREATE_SLUG",
                templateUrl: "templates/slug/edit-slug.html"
            })
            .when("/admin/slug/", {
                controller: "SlugsCtrl",
                controllerAs: "$ctrl",
                permissions: "CAN_ADMIN",
                reloadOnSearch: false,
                templateUrl: "templates/slug/slugs.html"
            })
            .when("/admin/slug/:id", {
                controller: "SlugCtrl",
                controllerAs: "$ctrl",
                permissions: "CAN_UPDATE_SLUG",
                templateUrl: "templates/slug/edit-slug.html"
            })
    }])
    .service("SlugService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        this.find = function(opts) {
            var queryOpts = {};

            opts || (opts = {});

            opts.filter && (queryOpts.q = $ngHatchUtils.getFilterQueryParam("srcPath", opts.filter));
            opts.offset && (queryOpts.offset = opts.offset);

            return $http.get(ApiBaseUri + "slug/", {params: queryOpts})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.load = function(id) {
            return $http.get(ApiBaseUri + "slug/" + id)
                .then(function(response) {
                    return response.data;
                })
                .then(function(mapping) {
                    return mapping;
                });
        };

        this.remove = function(id) {
            return $http.delete(ApiBaseUri + "slug/" + id);
        };

        this.save = function(mapping) {
            var config = {
                    method: mapping._id ? "put" : "post",
                    url: ApiBaseUri + "slug/" + (mapping._id || ""),
                    data: mapping
                };

            return $http(config)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    var json = response.data;
                    throw new Error(json.message);
                });
        };
    }])
    .controller("SlugCtrl", ["$location", "$log", "$ngHatchState", "$routeParams", "$scope", "SlugService", function($location, $log, $ngHatchState, $routeParams, $scope, slugService) {
        $scope.alerts = [];

        // setup the default model.
        this.mapping = {
            srcPath: "",
            targetPath: "",
            enabled: true
        };

        // if we were given an id on the url, load the record and populate the model.
        if ($routeParams.id) {
            slugService.load($routeParams.id)
                .then(function(mapping) {
                    this.mapping = mapping;
                }.bind(this));
        }

        /**
         * @ngDoc method
         * @name cancel
         * @methodOf SlugCtrl
         * @description cancel editing the record. All changes will be lost and the user will be returned to the
         * slug list.
         */
        this.cancel = function() {
            if ($scope.form.$dirty) {
                $log.debug("TODO: confirm");
            }

            $ngHatchState.location("/admin/slug");
        };

        /**
         * @ngDoc method
         * @name save
         * @methodOf SlugCtrl
         * @param mapping {Object} the Slug model.
         * @description save the Slug model.
         */
        this.save = function(mapping) {
            // if the form is not valid, don't send the save request...
            if ($scope.form.$invalid) {
                return;
            }

            slugService.save(mapping)
                .then(function() {
                    $ngHatchState.location("/admin/slug");
                })
                .catch(function(err) {
                    $log.error(err);
                    $scope.alerts.push({message: err.message, type: "danger"});
                });
        };
    }])
    .controller("SlugsCtrl", ["$location", "$log", "$scope", "SlugService", "debounce", "dialogs", function($location, $log, $scope, slugService, debounce, dialogs) {
        this.error = null;
        this.loading = false;

        this.mappings = [];

        // figure out the correct state for the page. The state may be obtained from the state store if it had
        // previously been stored, or derived from the url
        this.state = angular.extend({page: 1, q: ""}, $location.search());

        // derive the pageSize from the state, or use a default.
        this.pageSize = this.state.l || 10;

        // set an artificial total in the interim, or the paginator will reset the page to 1.
        this.total = this.pageSize * this.state.page;

        // react to the url changing. Update the state model and hit refresh to fetch the data.
        $scope.$on("$routeUpdate", function() {
            this.state = $location.search();
            this.refresh();
        }.bind(this));

        this.refresh = function() {
            var opts = {
                filter: this.state.q,
                offset: (this.state.page - 1) * this.pageSize,
                limit: this.pageSize
            };

            slugService.find(opts)
                .then(function(data) {
                    this.mappings = data.results;
                    this.total = data.total;
                }.bind(this))
                .catch(function(err) {
                    $log.warn("failed to get user data", err);
                    this.error = err.message;
                }.bind(this))
                .finally(function() {
                    this.loading = false;
                }.bind(this));

            this.loading = true;
        }.bind(this);

        this.remove = function(mapping) {
            var dialog = dialogs.confirm("Delete Mapping", "Are you sure you want to delete this mapping?"),
                self = this;

            dialog.result.then(function() {
                slugService.remove(mapping._id)
                    .then(function() {
                        self.refresh();
                    })
                    .catch(function() {
                        // TODO: handle failure to remove record.
                    });
            });
        }.bind(this);

        this.filter = debounce(120, function() {
            $location.search(this.state);
        }.bind(this));

        this.refresh();
    }]);
