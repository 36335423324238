angular.module("ngHatchMuseum", ["rt.debounce", "dialogs.main", "hnAmazon", "hnAssociations", "hnDatePicker", "hnExternalLink", "ngHatchUtils", "ngTagsInput", "uiSwitch", "ui.bootstrap", "ui.select", "uiw-rating", "uiwidgets-ng-tpls"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/museums/create", {
                controller: "MuseumCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_CREATE_MUSEUM",
                templateUrl: "templates/museum/edit-museum.html"
            })
            .when("/admin/museums/:id", {
                controller: "MuseumCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/museum/view-museum.html"
            })
            .when("/admin/museums/:museumId/add-exhibition", {
                controller: "ExhibitionCtrl",
                controllerAs: "ctrl",
                permissions: ["CAN_CREATE_EXHIBITION", "CAN_CREATE_EXHIBITION_EVENTS"],
                templateUrl: "templates/exhibition/edit-exhibition.html"
            })
            .when("/admin/museums/:id/edit", {
                controller: "MuseumCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_UPDATE_MUSEUM",
                templateUrl: "templates/museum/edit-museum.html"
            })
            .when("/admin/museums/:museumId/exhibitions/:id", {
                controller: "ExhibitionCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_UPDATE_EXHIBITION",
                templateUrl: "templates/exhibition/edit-exhibition.html"
            })
            .when("/admin/museums", {
                controller: "MuseumsCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_ADMIN",
                reloadOnSearch: false,
                templateUrl: "templates/museum/museums.html"
            });
    }])
    .service("MuseumIndexes", [function() {
        var indexes = [
                {key: "name", label: "name"},
                {key: "state", label: "state"},
                {key: "city", label: "City"}
            ];

        this.find = function() {
            return indexes;
        };

        this.lookup = function(i) {
            var index = indexes[0];

            if (i) {
                index = indexes.find(function(index) {
                    return index.key === i;
                });
            }

            return index;
        };
    }])
    .service("MuseumService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        /**
         * @param opts {Object} a query object.
         * @param opts.filter {String} the query string.
         * @param opts.index {String} "name"|"city"|"state"
         * @param opts.offset
         * @param opts.limit
         * @param opts.sort
         * @param opts.facets
         */
        this.find = function(opts) {
            const index = opts.index || "name";

            // transform the incoming find options into the form required on the url in order to send them with
            // the request.
            const queryOpts = {};
            
            opts.filter && (queryOpts.q = $ngHatchUtils.getFilterQueryParam(index, opts.filter));
            opts.offset && (queryOpts.offset = opts.offset);
            angular.isUndefined(opts.limit) || (queryOpts.limit = opts.limit);
            opts.sort && (queryOpts.sort = opts.sort);
            opts.fields && (queryOpts.fields = Array.isArray(opts.fields)
                ? opts.fields.join(",")
                : opts.fields);

            // transfer any facets to http parameters on the url.
            angular.forEach(opts.facets, function(value, facet) {
                queryOpts[facet] = value;
            });

            // build up the query parameters from the options object.
            angular.forEach(opts.where, function(val, prop) {
                if (val.$in) {
                    queryOpts[prop] = val.$in;
                } else {
                    queryOpts[prop] = val;
                }
            });

            return $http.get(ApiBaseUri + "museum/", {params: queryOpts})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.load = function(id) {
            return $http.get(ApiBaseUri + "museum/" + id)
                .then(function(response) {
                    return response.data;
                })
                .then(function(museum) {
                    museum.createdDate = new Date(museum.createdDate);
                    museum.lastModifiedDate = new Date(museum.lastModifiedDate);
                    museum.championExpiryDate = museum.championExpiryDate && new Date(museum.championExpiryDate);
                    return museum;
                });
        };

        this.getStates = function() {
            return $http.get(ApiBaseUri + "museum/states")
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.remove = function(id) {
            return $http.delete(ApiBaseUri + "museum/" + id);
        };

        this.save = function(museum) {
            var method = museum._id ? "put" : "post";
            return $http({
                    method: method,
                    url: ApiBaseUri + "museum/" + (museum._id || ""),
                    data: museum
                }).then(function(response) {
                    return response.data;
                });
        };

        this.deleteAmazonLink = function(museumId, linkId) {
            return $http({
                method: "delete",
                url: ApiBaseUri + "museum/" + museumId + "/amazon-links/" + linkId
            }).then(function(response) {
                return response.data;
            })
        };

        this.saveAmazonLink = function(museumId, link) {
            return $http({
                method: "post",
                url: ApiBaseUri + "museum/" + museumId + "/amazon-links",
                data: link
            }).then(function(response) {
                return response.data;
            });
        };

        this.saveAmazonLinks = function(museumId, links) {
            return $http({
                method: "put",
                url: ApiBaseUri + "museum/" + museumId + "/amazon-links",
                data: links
            }).then(function(response) {
                return response.data;
            });
        };

        this.deleteExternalLink = function(museumId, linkId) {
            return $http({
                method: "delete",
                url: ApiBaseUri + "museum/" + museumId + "/external-links/" + linkId
            }).then(function(response) {
                return response.data;
            })
        };

        this.saveExternalLink = function(museumId, link) {
            return $http({
                method: "post",
                url: ApiBaseUri + "museum/" + museumId + "/external-links",
                data: link
            }).then(function(response) {
                return response.data;
            });
        };
    }])

    /**
     * @ngDoc controller
     * @name MuseumCtrl
     * @description the controller for the edit/create museum screen.
     */
    .controller("MuseumCtrl", ["$hnDirtyNag", "$hnFormErrorMessage", "$log", "$ngHatchState", "$q", "$routeParams", "$scope", "$uibModal", "ExhibitionService", "MediaService", "MuseumService", "dialogs", "AssociationsService", "hnPatterns", "hnPermission", function($hnDirtyNag, $hnFormErrorMessage, $log, $ngHatchState, $q, $routeParams, $scope, $uibModal, ExhibitionService, MediaService, MuseumService, dialogs, AssociationsService, hnPatterns, hnPermission) {
        var museumId = $routeParams.id,
            initPromises = [MuseumService.getStates()],
            stopNag;

        this.states = [];
        this.isSaving = false;

        this.canPublish = hnPermission.all("CAN_PUBLISH_MUSEUM");
        this.canUpdateAssociations = hnPermission.all("CAN_UPDATE_MUSEUM_ASSOCIATIONS");

        $scope.patterns = hnPatterns;
        $scope.alerts = [];
        this.assocs = [];

        // setup the default model.
        this.museum = {
            body: "",
            tags: [],
            operatorNotes: []
        };
        this.events = [];

        /**
         * @ngDoc property
         * @name exhibitions
         * @propertyOf MuseumCtrl
         * @description the property that holds ALL exhibition records for the museum.
         */
        this.exhibitions = [];

        /**
         * @ngDoc property
         * @name displayExhibitions
         * @propertyOf MuseumCtrl
         * @description the property that holds the exhibition record that are displayed in the table.
         */
        this.displayExhibitions = [];

        /**
         * @ngDoc property
         * @name filterExpiredExhibitions
         * @propertyOf MuseumCtrl
         * @decription a property linked to the checkbox that enables a filter to show / hide expired exhibitions.
         */
        this.filterExpiredExhibitions = true;

        /**
         * @ngDoc method
         * @name filterExhibitions
         * @methodOf MuseumCtrl
         * @description if the expired exhibition filter is checked, filter the full set of exhibitions for the
         * displayed exhibitions, otherwise just use the full set of exhibitions.
         */
        this.filterExhibitions = function(exhibitions) {
            if (!this.filterExpiredExhibitions) {
                this.displayExhibitions = this.exhibitions;
                return
            }

            this.displayExhibitions = this.exhibitions.filter(function(exhibition) {
                return !exhibition.endDate || (exhibition.endDate.end > Date.now());
            });
        };

        // if we were given an id on the url, load the record and populate the model.
        if (museumId) {
            initPromises.push(MuseumService.load(museumId)
                .then(function(museum) {
                    // merge the loaded museum with the defaults and assign that to the controller, this is important
                    // for the operatorNotes because the template is referencing index 0.
                    this.museum = angular.extend(this.museum, museum);
                }.bind(this)));

            initPromises.push(ExhibitionService.find({where: {"events.museumId": museumId}, limit: 0})
                .then(function(response) {
                    // clear the set of exhibitions.
                    this.exhibitions = [];

                    angular.forEach(response.results, function(exhibition) {
                        angular.forEach(exhibition.events, function(event) {
                            // only add those exhibition events relevant to this museum.
                            if (event.museumId === museumId) {
                                this.exhibitions.push({
                                    _id: event._id,
                                    exhibition: exhibition,
                                    exhibitionId: exhibition._id,
                                    exhibitionTitle: exhibition.title,
                                    museumExhibitionLink: event.museumExhibitionLink,
                                    startDate: event.startDate,
                                    endDate: event.endDate,
                                    notes: event.notes
                                });
                            }
                        }.bind(this));
                    }.bind(this));

                    this.filterExhibitions(this.exhibitions);
                }.bind(this)));

            initPromises.push(AssociationsService.find()
                .then(function(response) {
                    this.assocs = response.results;
                }.bind(this)));
        }

        this.toolbar = [['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
            ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
            ['html', 'insertImage','insertLink', 'insertVideo', 'uploadImage']];

        // when the museum data an exhibition/events is loaded...
        $q.all(initPromises)
            .then(function(data) {
                this.states = data[0];

                // resolve associations bound to the museum from the overall list of associations.
                this.viewAssocs = this.assocs.filter(function(assoc) {
                    var associations = this.museum.associations || [];
                    return (associations.indexOf(assoc._id) !== -1);
                }.bind(this));

                // now all the data has loaded, if we have a form, register a $locationChangeStart handler to warn
                // the user if they attempt to navigate away while the form is dirty.
                if ($scope.form) {
                    stopNag = $hnDirtyNag(function() {
                        // we need to check for the existence of the form again, as it may have been destroyed.
                        return $scope.form && $scope.form.$dirty;
                    });
                }
            }.bind(this));

        /**
         * @ngDoc method
         * @name cancel
         * @methodOf MuseumCtrl
         * @description cancel editing the record. All changes will be lost and the user will be returned to the
         * museums list.
         */
        this.cancel = function(forceList) {
            var url = !forceList && this.museum._id ? "/admin/museums/" + this.museum._id : "/admin/museums";
            if ($scope.form && $scope.form.$dirty) {
                $log.debug("TODO: confirm");
            }

            $ngHatchState.location(url);
        }.bind(this);

        /**
         * @ngDoc method
         * @name eventDialog
         * @methodOf ExhibitionCtrl
         * @param [event] {Object} the event object to edit.
         * @param [index] {Number} the index of the event in the set of events.
         * @param [opts {Object} option for the dialog
         * @param [opts.exhibition] {Object} the exhibition object.
         * @param [opts.museum] {Object} the museum object.
         */
        this.eventDialog = function(event, index, opts) {
            var isNew = !event,
                modalInst;

            opts || (opts = {});

            modalInst = $uibModal.open({
                controller: "ExhibitionEventDialogCtrl",
                controllerAs: "ctrl",
                templateUrl: "templates/exhibition/event-dialog.html",
                resolve: {
                    "$event": event,
                    "$exhibition": opts.exhibition,
                    "$museum": opts.museum
                }
            });

            modalInst.result
                .then(function(event) {
                    // TODO: I don't think we need to do this now we're passing museum into the dialog.
                    if (opts.museum) {
                        event.museumId = opts.museum._id;
                        event.museumName = opts.museum.name;
                    }

                    // make sure the exhibitions array in the scope is initialised.
                    this.exhibitions || (this.exhibitions = []);

                    // save the event, on success, add to, or update the exhibitions array.
                    ExhibitionService.saveEvent(event.exhibitionId, event)
                        .then(function(event) {
                            // stuff the exhibition into the event object for the list.
                            opts.exhibition && (event.exhibition = opts.exhibition);
                            isNew ?  this.exhibitions.push(event) : this.exhibitions[index] = event;
                        }.bind(this));
                }.bind(this));
        };

        this.reviewed = function() {
            this.museum.lastReviewedDate = Date.now();
        }.bind(this);

        /**
         * @ngDoc method
         * @name mediaList
         * @methodOf MuseumCtrl
         * @returns {Promise} a promise for the media list.
         * @description get the media list for the museums record.
         */
        this.mediaList = function() {
            var imageIds = this.museum.images || [],
                results = [];

            if (imageIds.length) {
                // filter the imageIDs, so we don't pass undefined or null values to the MediaService. NOTE: any gaps
                // (where there are no imageIds, or there was no image are preserved later in the process.
                let filteredImageIds = imageIds.filter(function(imageId) {
                        return !!imageId;
                    });

                results = MediaService.find({where: {"_id": {"$in": filteredImageIds}}})
                    .then(function(items) {
                        // for all items returned, populate the "uri" and where appropriate, the "thumbUri" properties,
                        // at the same time, produce a hash of returned image data, keyed by image ID.
                        items = items.reduce(function(items, item) {
                            item.uri = MediaService.link(item.id);
                            if (item.thumb) {
                                item.thumbUri = MediaService.thumbLink(item.id);
                            }

                            items[item.id] = item;
                            return items;
                        }, {});

                        // use the list of images we asked for to generate an ordered list of image data, where images
                        // were not returned (i.e. missing in the database), enter a blank record, containing only the
                        // missing id.
                        items = imageIds.map(function(imageId) {
                            return items[imageId] || {id: imageId};
                        });

                        return items;
                    });
            }

            return $q.when(results);
        };

        /**
         * @ngDoc method
         * @name onDeleteAmazonLink
         * @methodOf MuseumCtrl
         * @param link {Object} the link object to remove.
         * @param index {Number} the index of the link in the list.
         * @return {Promise} a promise resolved when the amazon link is deleted.
         */
        this.onDeleteAmazonLink = function(link, index) {
            return MuseumService.deleteAmazonLink(this.museum._id, link._id);
        };

        /**
         * @ngDoc method
         * @name onDeleteExternalLink
         * @methodOf MuseumCtrl
         * @param link {Object} the link object to remove.
         * @param index {Number} the index of the link in the list.
         * @return {Promise} a promise resolved when the external link is deleted.
         */
        this.onDeleteExternalLink = function(link, index) {
            return MuseumService.deleteExternalLink(this.museum._id, link._id);
        };

        /**
         * @ngDoc method
         * @name onSaveAmazonLinks
         * @methodOf MuseumCtrl
         * @return {Promise} a promise for the saved links.
         * @description save a full set of amazon links.
         */
        this.onSaveAmazonLinks = function() {
            return MuseumService.saveAmazonLinks(this.museum._id, this.museum.amazonLinks);
        };

        /**
         * @ngDoc method
         * @name onUpdateAmazonLink
         * @methodOf MuseumCtrl
         * @param [link] {Object} the link object to edit.
         * @param [index] {Number} the index of the link in the set of links.
         * @return {Promise} a promise for the updated/created link object.
         * @description create/update an amazon link.
         */
        this.onUpdateAmazonLink = function(link, index) {
            return MuseumService.saveAmazonLink(this.museum._id, link);
        };

        /**
         * @ngDoc method
         * @name onUpdateExternalLink
         * @methodOf MuseumCtrl
         * @param [link] {Object} the link object to edit.
         * @param [index] {Number} the index of the link in the set of links.
         * @return {Promise} a promise for the updated/created link object.
         * @description create/update an external link.
         */
        this.onUpdateExternalLink = function(link, index) {
            return MuseumService.saveExternalLink(this.museum._id, link);
        };

        /**
         * @ngDoc method
         * @name onIngestComplete
         * @methodOf MuseumCtrl
         * @param mediaItem {Object} the ingested media item.
         * @description triggered when an image is uploaded via the rich text editor.
         */
        this.onIngestComplete = function(mediaItem) {
            // add the image id to the list of images bound to this record.
            var images = this.museum.images || (this.museum.images = []);
            images.push(mediaItem.id);
        }.bind(this);

        /**
         * @ngDoc method
         * @name onInsertMedia
         * @param mediaItem {Object} the inserted media item
         * @description triggered when an image is inserted via the insert media modal.
         */
        this.onInsertMedia = function(mediaItem) {
            // add the image id to the list of images bound to this record.
            var images = this.museum.images || (this.museum.images = []),
                idx = images.indexOf(mediaItem.id);

            if (idx === -1) {
                images.push(mediaItem.id);
            }
        };

        /**
         * @ngDoc method
         * @name onUnlinkMedia
         * @param mediaItem {Object} the selected media item
         * @description triggered when an image in unlinked from a museum.
         */
        this.onUnlinkMedia = function(mediaItem) {
            var images = this.museum.images || [],
                idx = images.indexOf(mediaItem.id);

            if (idx !== -1) {
                images.splice(idx, 1);
            }
        };

        this.exhibitionSearchResults = [];
        this.refreshExhibitionSearchResults = function(search) {
            ExhibitionService.find({"filter": search})
                .then(function(data) {
                    this.exhibitionSearchResults = data.results;
                }.bind(this));
        };

        /**
         * @ngDoc method
         * @name removeEvent
         * @param event {Object} the event object.
         * @param index {Number} the index of the event to remove in the UI.
         * @description triggered when the remove event button is clicked
         */
        this.removeEvent = function(event, index) {
            var dialog = dialogs.confirm("Delete Event", "Are you sure you want to delete this event?");

            dialog.result
                .then(function() {
                    return ExhibitionService.deleteEvent(event.exhibitionId, event._id);
                })
                .then(function() {
                    this.exhibitions.splice(index, 1);
                }.bind(this));

        };

        /**
         * @ngDoc method
         * @name save
         * @methodOf MuseumCtrl
         * @param museum {Object} the museum model.
         * @description save the museum model.
         */
        this.save = function(museum) {
            if (this.isSaving) {
                return;
            }

            // ngTagInput returns an array of objects, each with a `text` property. We'll need to flatten that to an
            // array of strings at this point.
            museum.tags = (museum.tags || []).map(function(item) {
                return angular.isString(item) ? item : item.text;
            });

            // if the form is not valid, don't send the save request...
            if ($scope.form && $scope.form.$invalid) {
                this.alerts = [{type: "danger", "message": $hnFormErrorMessage($scope.form)}];
                return;
            }

            // if the lastModifiedDate has not been changed, then update the lastModifiedDate to now.
            if (!$scope.form || !$scope.form.lastModifiedDate.$dirty) {
                museum.lastModifiedDate = Date.now();
            }

            this.isSaving = true;
            MuseumService.save(museum)
                .then(function(museum) {
                    // stop the location listener to prevent the nag when the intention is to save.
                    stopNag && stopNag();

                    // TODO: really should have a parameter with the new museum record here.
                    $ngHatchState.location("/admin/museums/" + museum._id);
                })
                .catch(function(response) {
                    $log.error(response);
                    $scope.alerts.push("failed to save Museum");
                })
                .finally(function() {
                    this.isSaving = false;
                }.bind(this));
        };
    }])
    .controller("MuseumsCtrl", ["$location", "$log", "$ngHatchState", "$scope", "MuseumIndexes", "MuseumService", "debounce", "dialogs", function($location, $log, $ngHatchState, $scope, MuseumIndexes, MuseumService, debounce, dialogs) {
        this.error = null;
        this.loading = false;

        this.museums = [];

        // figure out the correct state for the page. The state may be obtained from the state store if it had
        // previously been stored, or derived from the url
        this.state = angular.extend({page: 1, q: ""}, $location.search());

        // derive the pageSize from the state, or use a default.
        this.pageSize = this.state.l || 10;

        // get a list of indexes available.
        this.indexes = MuseumIndexes.find();

        // update the index property used for display.
        this.index = MuseumIndexes.lookup(this.state.i);

        // extract any facets from the url into the facets object.
        this.facets = {};
        angular.forEach(this.state, function(value, key) {
            if (key.substr(0, 3) === "fa_") {
                this.facets[key] = value;
            }
        }.bind(this));

        // set an artificial total in the interim, or the paginator will reset the page to 1.
        this.total = this.pageSize * this.state.page;

        // react to the url changing. Update the state model and hit refresh to fetch the data.
        $scope.$on("$routeUpdate", function() {
            this.state = $location.search();
            this.refresh();
        }.bind(this));

        this.refresh = function() {
            var opts = {
                    filter: this.state.q,
                    index: this.state.i,
                    offset: (this.state.page - 1) * this.pageSize,
                    limit: this.pageSize,
                    sort: this.state.sort,
                    facets: this.facets
                };

            MuseumService.find(opts)
                .then(function(data) {
                    this.museums = data.results;
                    this.total = data.total;
                }.bind(this))
                .catch(function(err) {
                    $log.warn("failed to get user data", err);
                    this.error = err.message;
                }.bind(this))
                .finally(function() {
                    this.loading = false;
                }.bind(this));

            this.loading = true;
        }.bind(this);

        /**
         * @ngDoc method
         * @name remove
         * @methodOf MuseumCtrl
         * @param page {Object} the museum model.
         * @description delete the museum model, prompting for confirmation first.
         */
        this.remove = function(museum) {
            var dialog = dialogs.confirm("Delete Museum", "Are you sure you want to delete this museum?"),
                self = this;

            dialog.result.then(function() {
                MuseumService.remove(museum._id)
                    .then(function() {
                        self.refresh();
                    })
                    .catch(function(err) {
                        $log.error(err);
                        $scope.alerts.push("failed to save museum");
                    });
            });

        }.bind(this);

        /**
         * @ngDoc method
         * @name storeState
         * @methodOf MuseumsCtrl
         * @description store the current page state in the store. This is invoked prior to navigation to pages where
         * we may want to return form. When using with anchor tags, calling this via ng-click will have the state stored
         * before the browser location is updated.
         */
        this.storeState = function() {
            $ngHatchState.location();
        }.bind(this);

        this.filter = debounce(120, function() {
            $location.search(this.state);
        }.bind(this));

        this.pageChange = function() {
            $location.search(this.state);
        }.bind(this);

        this.changeIndex = function(index) {
            this.index = index;
            this.state.i = index.key;

            // only re-search if there is query text.
            if (this.state.q) {
                $location.search(this.state);
            }
        }.bind(this);

        this.changeFacets = function(index) {
            if (this.facets.priority) {
                this.state.priority = this.facets.priority;
            } else {
                delete this.state.priority;
            }

            $location.search(this.state);
        }.bind(this);

        /**
         * @ngDoc method
         * @name changePageSize
         * @methodOf MuseumsCtrl
         * @description invoked when the page size is changed. Used to manipulate the state object, by setting
         * or deleting the "l" property. After the state has been updated, the url updated.
         */
        this.changePageSize = function() {
            if (this.pageSize !== 10) {
                this.state.l = this.pageSize;
            } else {
                delete this.state.l;
            }
            $location.search(this.state);
        }.bind(this);

        // update the search with the new sort order.
        this.sortBy = function() {
            $location.search(this.state);
        };

        this.refresh();
    }])
    .controller("MuseumListItemCtrl", ["$scope", function($scope) {
        // TODO: any custom logic for interaction with the list item.
    }])
    .directive("museumListItem", [function() {
        return {
            scope: {
                item: "=item"
            },
            controller: "MuseumListItemCtrl",
            templateUrl: "templates//list-item-museum.html"
        };
    }])
    /**
     * @ngDoc directive
     * @name amazonLinkTable
     * @description the table of amazon links, including edit/delete buttons.
     */
    .directive("heroImage", [function() {
        return {
            bindToController: true,
            restrict: "E",
            scope: {
                museum: "=",
                onSave: "&"
            },
            controller: ["$uibModal", "MediaService", function($uibModal, MediaService) {
                this.showDialog = () => {
                    var modalInst = $uibModal.open({
                        controller: ["images", function(images) {
                            this.images = images;
                            this.selectedId = images && images[0] || null;

                            this.save = () => {
                                const images = [...this.images];
                                const idx = images.findIndex(id => id === this.selectedId);
                                if (idx !== -1) {
                                    const removed = images.splice(idx, 1);
                                    images.unshift(removed[0]);
                                }

                                modalInst.close(images);
                            };

                            this.select = (event, imageId) => {
                                this.selectedId = imageId;
                            };

                            this.thumbUri = imageId => MediaService.thumbLink(imageId);
                        }],
                        controllerAs: "ctrl",
                        templateUrl: "templates/museum/hero-image-modal.html",
                        resolve: {
                            "images": () => this.museum && this.museum.images || []
                        }
                    });
        
                    modalInst.result
                        .then(images => {
                            this.museum.images = images;

                            this.onSave({museum: this.museum});
                        });
                };

                // function to the the URI of the current hero image.
                this.heroImageUri = () => {
                    var thumbId = this.museum && this.museum.images && this.museum.images[0];
                    return thumbId ? MediaService.thumbLink(thumbId) : null;
                };
            }],
            controllerAs: "ctrl",
            templateUrl: "templates/museum/hero-image.html"
        }
    }]);

