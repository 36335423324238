angular.module("ngHatchMedia", ["angularFileUpload"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/media", {
                controller: "MediaCtrl",
                templateUrl: "templates/media/media.html"
            })
            .when("/admin/media/upload", {
                controller: "MediaUploadCtrl",
                templateUrl: "templates/media/media-upload.html"
            });
    }])
    .service("MediaService", ["$http", "ApiBaseUri", "FileUploader", "hnAuth", function($http, ApiBaseUri, FileUploader, hnAuth) {
        /**
         * @ngDoc method
         * @name import
         * @methodOf MediaService
         * @param href {String} the href of the external resource to import.
         * @param [metaData] {Object} the image meta data.
         * @returns {Object}
         * @description import media from an external link.
         */
        this.import = function(href, metaData) {
            var promise = $http.post(ApiBaseUri + "media/import", {
                    uri: href,
                    metaData: metaData || {}
                });

            return {
                result: promise.then(function(response) {
                    return response.data;
                })
            };
        };

        this.link = function(id) {
            return ApiBaseUri + "media/" + id;
        };

        this.thumbLink = function(id) {
            return ApiBaseUri + "media/" + id + "/thumb";
        };

        this.distinct = function(fieldName, opts) {
            var queryParams = {};

            opts || (opts = {});

            // build up the query parameters from the options object.
            angular.forEach(opts.where, function(val, prop) {
                if (val.$in) {
                    queryParams[prop] = val.$in;
                }
            });

            return $http.get(ApiBaseUri + "media/distinct/" + fieldName, {params: queryParams})
                .then(function(response) {
                    return response.data;
                });
        };

        /**
         * @ngDoc method
         * @name find
         * @methodOf MediaService
         * @param [opts] {Object} the query object.
         *   @param [opts.where] {Object} the selection criteria object.
         * @returns {*|{Resolve, Reject}}
         */
        this.find = function(opts) {
            var queryParams = {};

            opts || (opts = {});

            // build up the query parameters from the options object.
            angular.forEach(opts.where, function(val, prop) {
                if (val.$in) {
                    queryParams[prop] = val.$in;
                }
            });

            return $http.get(ApiBaseUri + "media", {params: queryParams})
                .then(function(response) {
                    return response.data;
                });
        };

        this.regenerateThumb = function(id) {
            return $http.get(ApiBaseUri + "media/" + id + "/thumb/regenerate", {});
        };

        this.remove = function(id) {
            return $http.delete(ApiBaseUri + "media/" + id);
        };

        this.update = function(id, body) {
            return $http.put(ApiBaseUri + "media/" + id, body || {})
                .then(function(response) {
                    return response.data;
                });
        };

        this.uploader = function() {
            var uploader = new FileUploader({url: this.uploadUrl()});

            uploader.onBeforeUploadItem = function(item) {
                hnAuth.applyAuthHeader(function(headerName, headerValue) {
                    item.headers[headerName] = headerValue;
                });
            };

            return uploader;
        };

        this.uploadUrl = function() {
            return ApiBaseUri + "media/";
        };
    }])
    .controller("MediaCtrl", ["$scope", "MediaService", function($scope, MediaService) {
        $scope.files = [];

        function refresh() {
            MediaService.find()
                .then(function(files) {
                    $scope.files = [];
                    files.forEach(function(file) {
                        $scope.files.push(angular.extend({uri: MediaService.link(file.id)}, file));
                    });
                });
        }

        $scope.remove = function(id) {
            MediaService.remove(id)
                .then(function() {
                    // TODO: success notification.
                    refresh();
                })
                .catch(function(err) {
                    // TODO: error notification.
                })
        };

        $scope.regenerateThumb = function(id) {
            MediaService.regenerateThumb(id)
                .then(function() {
                    // success
                })
                .catch(function(err) {
                    // failure
                })
        };

        refresh();
    }])
    .controller("MediaUploadCtrl", ["$scope", "MediaService", function($scope, mediaService) {
        $scope.uploader = mediaService.uploader();
    }]);
