"use strict";

angular.module("ngHatchUser", ["rt.debounce", "hnRole", "ngHatchUtils", "ngTagsInput", "uiSwitch", "ui.bootstrap"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/profile", {
                templateUrl: "templates/user/profile.html"
            })
            .when("/admin/users/create", {
                controller: "UserCtrl",
                controllerAs: "$ctrl",
                permissions: ["CAN_CREATE_USER"],
                templateUrl: "templates/user/edit-user.html"
            })
            .when("/admin/users/:id", {
                controller: "UserCtrl",
                controllerAs: "$ctrl",
                permissions: ["CAN_UPDATE_USER"],
                templateUrl: "templates/user/edit-user.html"
            })
            .when("/admin/users", {
                controller: "UsersCtrl",
                controllerAs: "$ctrl",
                permissions: ["CAN_QUERY_USERS"],
                templateUrl: "templates/user/users.html"
            });
    }])
    .service("UserService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        this.load = function(id) {
            return $http.get(ApiBaseUri + "user/" + id)
                .then(function(response) {
                    return response.data;
                })
                .then(function(user) {
                    user.createdDate = new Date(user.createdDate);
                    user.lastModifiedDate = new Date(user.lastModifiedDate);

                    return user;
                });
        };

        this.remove = function(id) {
            return $http.delete(ApiBaseUri + "user/" + id);
        };

        this.find = function(opts) {
            var queryOpts = {},
                q = [];

            // transform the incoming find options into the form required on the url in order to send them with
            // the request.
            opts.offset && (queryOpts.offset = opts.offset);
            angular.isUndefined(opts.limit) || (queryOpts.limit = opts.limit);
            opts.sort && (queryOpts.sort = opts.sort);

            // assemble q.
            
            opts.filter && (q.push($ngHatchUtils.getFilterQueryParam("username", opts.filter)));
            opts.showOnlyStaffUsers && (q.push("!roles.isEmpty()"));

            if (q.length) {
                queryOpts.q = q.join(" and ");
            }

            // build up the query parameters from the options object.
            angular.forEach(opts.where, function(val, prop) {
                if (val.$in) {
                    queryOpts[prop] = val.$in;
                } else {
                    queryOpts[prop] = val;
                }
            });

            return $http.get(ApiBaseUri + "user/", {params: queryOpts})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.save = function(user) {
            var method = user._id ? "put" : "post";
            return $http({
                    method: method,
                    url: ApiBaseUri + "user/" + (user._id || ""),
                    data: user
                }).then(function(response) {
                    return response.data;
                }).catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };
    }])
    .controller("UserCtrl", ["$location", "$log", "$ngHatchState", "$routeParams", "$scope", "RoleService", "UserService", function($location, $log, $ngHatchState, $routeParams, $scope, RoleService, UserService) {
        $scope.alerts = [];

        this.roles = [];

        RoleService.find()
            .then(function(roles) {
                this.roles = roles;
            }.bind(this));

        // setup the default model.
        this.user = {
            username: ""
        };

        // if we were given an id on the url, load the record and populate the model.
        if ($routeParams.id) {
            UserService.load($routeParams.id)
                .then(function(user) {
                    user.roles = user.roles.reduce(function(hash, key) {
                        hash[key] = true;
                        return hash;
                    }, {});

                    this.user = user;
                }.bind(this));
        }

        this.cancel = function() {
            if ($scope.form.$dirty) {
                $log.debug("TODO: confirm");
            }

            $location.path("admin/users");
        };

        this.save = function(user) {
            // if the form is not valid, don't send the save request...
            if ($scope.form.$invalid) {
                return;
            }

            // the array of checkboxes translates to hash, so we have to turn that into an array.
            user.roles = (function(roles) {
                var result = [];
                angular.forEach(roles, function(value, key) {
                    if (value) {
                        result.push(key);
                    }
                });
                return result;
            })(user.roles);

            // update the lastModifiedDate.
            user.lastModifiedDate = Date.now();

            UserService.save(user)
                .then(function(user) {
                    $ngHatchState.location("/admin/users");
                })
                .catch(function(err) {
                    $log.error(err);
                    $scope.alerts.push({type: "danger", message: err.message});
                });
        };
    }])
    .controller("UsersCtrl", ["$http", "$location", "$log", "$ngHatchState", "$scope", "UserService", "debounce", "dialogs", function($http, $location, $log, $ngHatchState, $scope, UserService, debounce, dialogs) {
        this.error = null;
        this.loading = false;
        this.users = [];
        this.showOnlyStaffUsers = false;

        // figure out the correct state for the page. The state may be obtained from the state store if it had
        // previously been stored, or derived from the url
        this.state = angular.extend({page: 1, q: ""}, $location.search());

        // derive the pageSize from the state, or use a default.
        this.pageSize = this.state.l || 10;

        // set an artificial total in the interim, or the paginator will reset the page to 1.
        this.total = this.pageSize * this.state.page;

        // react to the url changing. Update the state model and hit refresh to fetch the data.
        $scope.$on("$routeUpdate", function() {
            this.state = $location.search();
            this.refresh();
        }.bind(this));

        this.refresh = function() {
            var opts = {
                    filter: this.state.q,
                    showOnlyStaffUsers: this.showOnlyStaffUsers,
                    offset: (this.state.page - 1) * this.pageSize,
                    limit: this.pageSize,
                    sort: this.state.sort
                };

            UserService.find(opts)
                .then(function(data) {
                    this.users = data.results;
                    this.total = data.total;
                }.bind(this))
                .catch(function(err) {
                    $log.warn("failed to get user data", err);
                    this.error = err.message;
                }.bind(this))
                .finally(function() {
                    this.loading = false;
                }.bind(this));

            this.loading = true;
        };

        this.remove = function(id) {
            var dialog = dialogs.confirm("Delete User", "Are you sure you want to delete this user?"),
                self = this;

            dialog.result.then(function() {
                UserService.remove(id)
                    .then(function() {
                        self.refresh();
                    })
                    .catch(function(err) {
                        // TODO: handle failure to remove record.
                        $log.warn("failed to delete user: " + id, err);
                        self.error = err.message;
                    });
            });
        };

        /**
         * @ngDoc method
         * @name storeState
         * @methodOf MuseumsCtrl
         * @description store the current page state in the store. This is invoked prior to navigation to pages where
         * we may want to return form. When using with anchor tags, calling this via ng-click will have the state stored
         * before the browser location is updated.
         */
        this.storeState = function() {
            $ngHatchState.location();
        }.bind(this);

        this.filter = debounce(120, function() {
            $location.search(this.state);
        }.bind(this));

        this.pageChange = function() {
            $location.search(this.state);
        }.bind(this);

        /**
         * @ngDoc method
         * @name changePageSize
         * @methodOf MuseumsCtrl
         * @description invoked when the page size is changed. Used to manipulate the state object, by setting
         * or deleting the "l" property. After the state has been updated, the url updated.
         */
        this.changePageSize = function() {
            if (this.pageSize !== 10) {
                this.state.l = this.pageSize;
            } else {
                delete this.state.l;
            }
            $location.search(this.state);
        }.bind(this);

        // update the search with the new sort order.
        this.sortBy = function() {
            $location.search(this.state);
        };

        // initial data load...
        this.refresh();
    }]);
