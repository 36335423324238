"use strict";

angular.module("ngReports", [])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/reports", {
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/reports.html"
            })
            .when("/admin/reports/eventsStartingEndingMonth", {
                controller: ["ReportsService", function(reportsService) {
                    const now = new Date();

                    this.starting = "true";
                    this.month = now.getMonth();
                    this.year = now.getFullYear();

                    const doSearch = (month, year, starting) => {
                        reportsService.eventsStartingEndingMonth(month, year, starting === "true")
                            .then((data) => {
                                this.events = data.results;
                                this.total = data.total;
                            });
                    }

                    doSearch(this.month, this.year, this.starting);

                    this.search = () => {
                        doSearch(this.month, this.year, this.starting)
                    };
                }],
                controllerAs: "$report",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/eventsStartingEndingMonth.html"
            })
            .when("/admin/reports/exhibitionsActive", {
                controller: ["ReportsService", function(reportsService) {
                    reportsService.exhibitionsActive()
                        .then((data) => {
                            this.active = data.active;
                            this.total = data.total;
                        });

                }],
                controllerAs: "$report",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/exhibitionsActive.html"
            })
            .when("/admin/reports/exhibitionsUnpublished", {
                controller: ["ReportsService", function(reportsService) {
                    reportsService.exhibitionsUnpublished()
                        .then((data) => {
                            this.results = data.results;
                            this.total = data.total;
                        });
                }],
                controllerAs: "$report",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/exhibitionsUnpublished.html"
            })
            .when("/admin/reports/images", {
                controller: ["ReportsService", "$anchorScroll", function(reportsService, $anchorScroll) {
                    this.data = [];
                    this.fullData = [];

                    this.goto = (hash) => {
                        $anchorScroll(hash);
                    };

                    this.state1 = {
                        data: [],
                        fullData: [],
                        indexes: [
                            {key: "name", label: "Name"},
                            {key: "city", label: "City"},
                            {key: "state", label: "State"}
                        ],
                        q: "",
                        sort: ""
                    };
                    this.state1.index = this.state1.indexes[0];

                    this.state2 = {
                        data: [],
                        fullData: [],
                        indexes: [
                            {key: "name", label: "Name"},
                            {key: "city", label: "City"},
                            {key: "state", label: "State"}
                        ],
                        q: "",
                        sort: ""
                    }
                    this.state2.index = this.state2.indexes[0];

                    this.changeIndex = (state, index) => {
                        state.index = index;
                    };

                    this.filter = (state) => {
                        let query = (state.q || "").trim().toLowerCase();
                        if (!query) {
                            state.data = state.fullData;
                            return;
                        }

                        let key = state.index.key;
                        state.data = state.fullData.filter((record) => {
                            if (!record[key]) {
                                return;
                            }
                            let value = record[key].toLowerCase();
                            return value.indexOf(query) >= 0;
                        });

                        this.sortBy(state);
                    };

                    this.sortBy = (state) => {
                        let field = state.sort;
                        let direction = "desc";

                        if (field[0] === "-") {
                            direction = "asc";
                            field = field.substr(1);
                        }

                        state.data.sort(sortFn(field, direction));
                    };

                    reportsService.images()
                        .then((data) => {
                            this.fullData = data;
                            this.state1.fullData = data.museums.deadImages;
                            this.state2.fullData = data.museums.noImages;
                            this.filter(this.state1);
                            this.filter(this.state2);
                            this.total = data.total;
                        });
                }],
                controllerAs: "$report",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/images.html"
            })
            .when("/admin/reports/museumsWithoutActiveEvents", {
                controller: ["ReportsService", function(reportsService) {
                    this.data = [];
                    this.fullData = [];
                    this.indexes = [
                        {key: "name", label: "Name"},
                        {key: "city", label: "City"},
                        {key: "state", label: "State"}
                    ];
                    this.index = this.indexes[0];
                    this.q = "";
                    this.sort = "";

                    this.changeIndex = (index) => {
                        this.index = index;
                    };

                    this.filter = () => {
                        let query = (this.q || "").trim().toLowerCase();
                        if (!query) {
                            this.data = this.fullData;
                            return;
                        }

                        let key = this.index.key;
                        this.data = this.fullData.filter((record) => {
                            if (!record[key]) {
                                return;
                            }
                            let value = record[key].toLowerCase();
                            return value.indexOf(query) >= 0;
                        });

                        this.sortBy();
                    };

                    this.sortBy = () => {
                        let field = this.sort;
                        let direction = "desc";

                        if (field[0] === "-") {
                            direction = "asc";
                            field = field.substr(1);
                        }

                        this.data.sort(sortFn(field, direction));
                    };

                    reportsService.museumsWithoutActiveEvents()
                        .then((data) => {
                            this.fullData = data.museums;
                            this.filter();
                            this.total = data.totalMuseums;
                        });
                }],
                controllerAs: "$report",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/museumsWithoutActiveEvents.html"
            })
            .when("/admin/reports/museumsWithoutPriority", {
                controller: ["ReportsService", function(reportsService) {
                    this.data = [];
                    this.fullData = [];
                    this.indexes = [
                        {key: "name", label: "Name"},
                        {key: "city", label: "City"},
                        {key: "state", label: "State"}
                    ];
                    this.index = this.indexes[0];
                    this.q = "";
                    this.sort = "";

                    this.changeIndex = (index) => {
                        this.index = index;
                    };

                    this.filter = () => {
                        let query = (this.q || "").trim().toLowerCase();
                        if (!query) {
                            this.data = this.fullData;
                            return;
                        }

                        let key = this.index.key;
                        this.data = this.fullData.filter((record) => {
                            if (!record[key]) {
                                return;
                            }
                            let value = record[key].toLowerCase();
                            return value.indexOf(query) >= 0;
                        });

                        this.sortBy();
                    };

                    this.sortBy = () => {
                        let field = this.sort;
                        let direction = "desc";

                        if (field[0] === "-") {
                            direction = "asc";
                            field = field.substr(1);
                        }

                        this.data.sort(sortFn(field, direction));
                    };

                    reportsService.museumsWithoutPriority()
                        .then((data) => {
                            this.fullData = data.results;
                            this.filter();
                            this.total = data.total;
                        });
                }],
                controllerAs: "$report",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/museumsWithoutPriority.html"
            })
            .when("/admin/reports/museumsWithChampionStatus", {
                controller: ["ReportsService", function(reportsService) {
                    reportsService.museumsWithChampionStatus()
                        .then((data) => {
                            this.results = data.results;
                            this.results.sort((a, b) => {
                                if (a.championExpiryDate < b.championExpiryDate) {
                                    return -1;
                                } else if (a.championExpiryDate > b.championExpiryDate) {
                                    return 1;
                                }
                                return 0;
                            });
                            this.total = data.total;
                        });
                }],
                controllerAs: "$report",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/museumsWithChampionStatus.html"
            })
            .when("/admin/reports/contentWithAmazonLinks", {
                controller: ["ReportsService", function(reportsService) {
                    reportsService.contentWithAmazonLinks()
                        .then(data => {
                            this.exhibitions = data.exhibitions;
                            this.museums = data.museums;
                        });
                }],
                controllerAs: "$report",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/contentWithAmazonLinks.html"
            })
            .when("/admin/reports/museumsWithoutTags", {
                controller: ["ReportsService", function(reportsService) {
                    this.sort = "";

                    reportsService.museumsWithoutTags()
                        .then((data) => {
                            this.fullResults = data.results;
                            this.total = data.total;
                            this.filter();
                        });

                    this.filter = () => {
                        const query = (this.q || "").trim().toLowerCase();

                        this.results = this.fullResults;
                        if (query) {
                            this.results = this.fullResults.filter(record => {
                                const value = record.name.toLowerCase();
                                return value.indexOf(query) >= 0;
                            });
                        }

                        this.sortBy();
                    };
    
                    this.sortBy = () => {
                        let field = this.sort;
                        let direction = "desc";

                        if (field[0] === "-") {
                            direction = "asc";
                            field = field.substr(1);
                        }

                        this.results.sort(sortFn(field, direction));
                    };    
                }],
                controllerAs: "$report",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/reports/museumsWithoutTags.html"
            })
    }])
    .service("ReportsService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        this.images = function() {
            return $http.get(ApiBaseUri + "reports/images")
                .then((response) => {
                    return response.data;
                })
                .catch((response) => {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.eventsStartingEndingMonth = (month, year, starting) => {
            const queryParams = `month=${month}&year=${year}&starting=${starting}`;
            return $http.get(`${ApiBaseUri}reports/eventsStartingEndingMonth?${queryParams}`)
                .then((response) => {
                    response.data.results = response.data.results.map((result) => {
                        result.startDate && result.startDate.start && (result.startDate.start = new Date(result.startDate.start));
                        result.startDate && result.startDate.end && (result.startDate.end = new Date(result.startDate.end));
                        result.endDate && result.endDate.start && (result.endDate.start = new Date(result.endDate.start));
                        result.endDate && result.endDate.end && (result.endDate.end = new Date(result.endDate.end));
                        return result;
                    })

                    response.data.results.sort((a,b) => {
                        const aDate = starting ? a.startDate.start : a.endDate.end;
                        const bDate = starting ? b.startDate.start : b.endDate.end;
                        if (aDate > bDate) {
                            return 1;
                        } else if (aDate < bDate) {
                            return -1;
                        }
                        return 0;
                    });

                    return response.data;
                })
                .catch((response) => {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.exhibitionsActive = () => {
            return $http.get(ApiBaseUri + "reports/exhibitionsActive")
                .then((response) => {
                    return response.data;
                })
                .catch((response) => {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.exhibitionsUnpublished = () => {
            return $http.get(ApiBaseUri + "reports/exhibitionsUnpublished")
                .then((response) => {
                    return response.data;
                })
                .catch((response) => {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.museumsWithoutPriority = () => {
            return $http.get(ApiBaseUri + "reports/museumsWithoutPriority")
                .then((response) => {
                    return response.data;
                })
                .catch((response) => {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.museumsWithoutActiveEvents = () => {
            return $http.get(ApiBaseUri + "reports/museumsWithoutActiveEvents")
                .then((response) => {
                    return response.data;
                })
                .catch((response) => {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.museumsWithChampionStatus = () => {
            return $http.get(ApiBaseUri + "reports/museumsWithChampionStatus")
                .then((response) => {
                    return response.data;
                })
                .catch((response) => {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.museumsWithoutTags = () => {
            return $http.get(ApiBaseUri + "reports/museumsWithoutTags")
                .then(response => {
                    return response.data;
                })
                .catch(response => {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.contentWithAmazonLinks = () => {
            return $http.get(ApiBaseUri + "reports/contentWithAmazonLinks")
                .then(response => {
                    response.data.exhibitions.sort((a, b) => {
                        if ((a.endDate === null) && (b.endDate !== null)) {
                            return -1;
                        } else if (a.endDate === null && b.endDate === null) {
                            return 0;
                        } else if (a.endDate && b.endDate) {
                            if (a.endDate > b.endDate) {
                                return -1;
                            } else if (a.endDate < b.endDate) {
                                return 1;
                            }
                            return 0;
                        }
                    });
                    response.data.exhibitions = response.data.exhibitions.map(exhibition => {
                        if (exhibition.endDate) {
                            exhibition.endDate = new Date(exhibition.endDate);
                        }
                        return exhibition;
                    });
                    return response.data;
                })
                .catch(response => {
                    $ngHatchUtils.httpError(response);
                });
        };
    }]);

const sortFn = (field, direction) => {
    if (direction === "asc") {
        return (a, b) => {
            let lhs = a[field] || "";
            let rhs = b[field] || "";

            if (lhs < rhs) {
                return -1;
            } else if (lhs > rhs) {
                return 1;
            }
            return 0;
        };
    }

    return (a, b) => {
        let lhs = a[field] || "";
        let rhs = b[field] || "";

        if (lhs < rhs) {
            return 1;
        } else if (lhs > rhs) {
            return -1;
        }
        return 0;
    }
}
