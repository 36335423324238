angular.module("ngHatchCategory", [])
    .service("CategoryService", ["$http", "ApiBaseUri", function($http, ApiBaseUri) {
        /**
         * @ngDoc method
         * @name load
         * @param [key] {String} a "." delimited category path.
         * @returns {Promise} A promise for an array of category objects.
         * @description load category data.
         */
        this.load = function(key) {
            var path = key ? key.replace(".", "/") : "";
            return $http.get(ApiBaseUri + "category/" + path)
                .then(function(response) {
                    return response.data;
                });
        };

        /**
         * @ngDoc method
         * @name flatten
         * @param keys {Array} an array of "." delimited category paths.
         * @param categories {Object} a category hierarchy.
         * @description flatten a set of category paths into a flat array of category objects. Where a category path
         * has depth (i.e. a sub-category) a category object is created for each of the parent categories. e.g.
         * ["a.b.c", "d"] would result in ["a", "a.b", "a.b.c", "d"];
         */
        this.flatten = function(keys, categories, includeTop) {
            var flat = []
            angular.forEach(keys, function(key) {
                var components = Array.isArray(key) ? key : key.split("."),
                    categorySet = categories,
                    parent = null,
                    component;

                while (component = components.shift()) {
                    component = categorySet && categorySet.find(function(category) {
                        return category.key === component;
                    });
                    if (component) {
                        if (includeTop || parent) {
                            flat.push({label: component.label});
                        }
                        parent = categorySet;
                        categorySet = component.subs;
                    }
                }
            });
            return flat;
        };
    }]);