angular.module("hnDatePicker", ["ui.bootstrap.datepicker"])
    .directive("hnDatePicker", ["$parse", function($parse) {
        return {
            controller: function() {
                this.isOpen = false;
                this.value = null;
                this.disabled = false;
            },
            controllerAs: "$hnDatePickerCtrl",
            link: function(scope, element, attrs, ctrls) {
                var ctrl = ctrls[0],
                    ngModelCtrl = ctrls[1];

                // respect ng-disabled.
                if (angular.isDefined(attrs.ngDisabled)) {
                    scope.$parent.$watch($parse(attrs.ngDisabled), function(value) {
                        ctrl.disabled = scope.$parent.$eval(attrs.ngDisabled);
                    });
                }

                // respect ng-model.
                if (ngModelCtrl) {
                    ngModelCtrl.$render = function() {
                        ctrl.value = ngModelCtrl.$viewValue;
                    };

                    scope.$watch("$hnDatePickerCtrl.value", function(value) {
                        ngModelCtrl.$setViewValue(value);
                    });
                }
            },
            require: ["hnDatePicker", "?ngModel"],
            scope: {},
            templateUrl: "templates/hn/hn-date-picker.html"
        }
    }]);
