angular.module("ngHatchFuzzyDate", [])
    .directive("hnFuzzydate", [function() {
        return {
            link: function(scope, element, attrs, ngModelCtrl) {
                var date = null;

                // a flag that determines if we're entering fuzzy dates or not.
                scope.fuzzy = false;

                scope.date = null;
                scope.fuzzyValue = "";

                scope.orientation = attrs.orientation === 'end' ? 'end' : 'start';
                scope.value = {};

                /**
                 * @ngDoc method
                 * @name updateDate
                 * @param newVal {Date} the Date object for the selected date.
                 * @description Update the date component (start/end) in response to a date selection.
                 */
                scope.updateDate = function(newVal) {
                        scope.value = {};
                        scope.value[scope.orientation] = newVal;
                };

                /**
                 * @ngDoc method
                 * @name toggle
                 * @description toggle between fuzzy date and selected date.
                 */
                scope.toggle = function() {
                    scope.value = {};
                    if (scope.fuzzy) {
                        date = scope.date;
                        scope.date = null;

                        scope.value.fuzzy = scope.fuzzyValue;
                    } else {
                        scope.date = date;
                        scope.value[scope.orientation] = scope.date;
                    }
                };

                /**
                 * @ngDoc method
                 * @name updateFuzzyValue
                 * @param value {String} the fuzzy value.
                 * @description Update the fuzzy date value in response a change via the UI.
                 */
                scope.updateFuzzyValue = function(value) {
                    scope.value = {};
                    scope.value.fuzzy = value;
                };

                if (ngModelCtrl) {
                    ngModelCtrl.$render = function() {
                        scope.value = ngModelCtrl.$viewValue;
                        if (scope.value) {
                            if (scope.value.fuzzy) {
                                scope.fuzzyValue = scope.value.fuzzy;
                                scope.fuzzy = !!scope.fuzzyValue;
                            } else {
                                scope.date = scope.value[scope.orientation];
                            }
                        }
                    };

                    scope.$watch("value", function(newVal) {
                        ngModelCtrl.$setViewValue(newVal);
                    });
                }
            },
            require: "?ngModel",
            scope: {},
            templateUrl: "templates/hn/hn-fuzzydate.html"
        };
    }]);