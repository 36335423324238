import $ from "jquery";
import angular from "angular";
import "angular-clipboard";
import "angular-debounce";
import "angular-dialog-service";
import "angular-file-upload";
import "angular-messages";
import "angular-moment";
import "angular-password";
import "angular-route";
import "angular-ui-bootstrap";
import "angular-ui-switch";
import "ng-autofocus/autofocus";
import "ng-tags-input";
import "satellizer";
import "textangular/dist/textAngular-sanitize";
import "textangular";
import "uiwidgets-ng/dist/uiwidgets-ng.min.js";
import "uiwidgets-ng/dist/uiwidgets-ng-tpls";
import "ui-select";
import "./hnAmazon";
import "./hnAssociations";
import "./hnAuth";
import "./hnConfig";
import "./hnDateOverride";
import "./hnDatePicker";
import "./hnExternalLink";
import "./hnFrontPage";
import "./hnRole";
import "./hnSettings";
import "./hnStaticPages";
import "./ng-hatch-article";
import "./ng-hatch-artist";
import "./ng-hatch-category";
import "./ng-hatch-dropdown";
import "./ng-hatch-exhibition";
import "./ng-hatch-fuzzydate";
import "./ng-hatch-media";
import "./ng-hatch-museum";
import "./ng-hatch-tag";
import "./ng-hatch-textangular";
import "./ng-hatch-user";
import "./ng-hatch-utils";
import "./ngReports";
import "./ngSlug";

import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "uiwidgets-ng/dist/uiwidgets-ng.min.css";
import "textangular/dist/textAngular.css";
import "angular-dialog-service/dist/dialogs.css";
import "angular-ui-switch/angular-ui-switch.css";
import "ng-tags-input/build/ng-tags-input.css";
import "ng-tags-input/build/ng-tags-input.bootstrap.css";
import "ui-select/dist/select.css";
import "../skin.css";
import "../theme.css";

window.jquery = window.$ = $;

// FIX: monkey patch angular.lowercase. It was removed in angular 1.6.7
angular.lowercase = angular.$$lowercase;

angular.module("timesheet", ["angularMoment", "hnAuth", "hnConfig", "hnFrontPage", "hnSettings", "hnStaticPages", "ngRoute", "textAngular", "autofocus", "ngHatchArticle",
    "ngHatchExhibition", "ngHatchMedia", "ngHatchMuseum", "ngHatchTag", "ngHatchTextAngular", "ngHatchUser", "ngMessages", "ngPassword", "ngReports", "ngSlug", "ui.bootstrap"])

    .config(["$locationProvider", "$routeProvider", "$uibTooltipProvider", function($locationProvider, $routeProvider, $uibTooltipProvider) {
        $locationProvider.html5Mode(true);

        $routeProvider
            .when("/", {
                templateUrl: "templates/home.html"
            })
            .when("/login", {
                controller: "LoginCtrl",
                templateUrl: "templates/auth/login.html"
            })
            .when("/register", {
                controller: "RegisterCtrl",
                templateUrl: "templates/user/register.html"
            })
            .when("/unauthorised", {
                controller: "LoginCtrl",
                templateUrl: "templates/auth/unauthorised.html"
            })
            .when("/admin", {
                templateUrl: "templates/admin/index.html",
                permissions: "CAN_ADMIN"
            });

        $uibTooltipProvider.options({
            appendToBody: true
        });
    }])
    .run(["$auth", "$location", "$log", "$rootScope", function($auth, $location, $log, $rootScope) {

        $rootScope.$on("$routeChangeSuccess", function(ev, next) {
            // look at the route and set the "admin" class if we're in an admin route.
            $rootScope.admin = next && next.originalPath.substr(0, 6) === "/admin";
            angular.element(document.body).toggleClass("admin", $rootScope.admin);
        });
    }])
    .directive("hatchHeaderMenu", function() {
        return {
            controller: function() {

            }
        };
    })
    .directive("hatchHeaderMenuItem", ["$log", function($log) {
        return {
            link: function(scope, element, attrs, headerMenu) {
                scope.$on("$routeChangeSuccess", function(ev, next) {
                    var trunc = next && next.originalPath.substr(0, attrs.hatchHeaderMenuItem.length),
                        active = (trunc === attrs.hatchHeaderMenuItem);

                    element.toggleClass("active", active);
                });
            },
            require: "^hatchHeaderMenu"
        }
    }])
    .controller("HeaderCtrl", ["$location", "hnAuth", function($location, hnAuth) {
        this.logout = function() {
            hnAuth.logout()
                .then(function() {
                    // redirect to the home page on logout.
                    $location.path("/");
                });
        };
    }])
    .controller("LoginCtrl", ["$location", "$scope", "hnAuth", function($location, $scope, hnAuth) {
        $scope.form = {};

        $scope.login = function(form) {
            hnAuth.login(form)
                .then(function() {
                    // redirect the user to the home page.
                    $location.path("/");
                })
                .catch(function() {
                    $scope.formCtrl.username.$setValidity("invalidUsernamePassword", false);
                });
        };
    }])
    .controller("RegisterCtrl", ["$http", "$location", "$log", "$scope", "ApiBaseUri", function($http, $location, $log, $scope, ApiBaseUri) {
        $scope.confirm = {};
        $scope.user = {};

        $scope.register = function(user) {
            // if the form is not valid, don't send the registration request...
            if ($scope.formCtrl.$invalid) {
                return;
            }

            $http.post(ApiBaseUri + "user/register", user)
                .then(function() {
                    // successful registration, redirect to login page.
                    $location.path("/login");
                })
                .catch(function(response) {
                    if (response.data.message === "user_already_exists") {
                        $scope.formCtrl.email.$setValidity("userAlreadyExists", false);
                        return;
                    } else if (response.data.message === "username_already_taken") {
                        $scope.formCtrl.username.$setValidity("usernameAlreadyTaken", false);
                        return;
                    }

                    $log.error(response);
                });
        };
    }])
    .constant("hnPatterns", {
        url: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/
    });
