angular.module("ngHatchArticle", ["ngHatchUtils", "ngTagsInput", "uiSwitch"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/articles/create", {
                controller: "ArticleCtrl",
                controllerAs: "articleCtrl",
                templateUrl: "templates/article/edit-article.html"
            })
            .when("/admin/articles/:id", {
                controller: "ArticleCtrl",
                controllerAs: "articleCtrl",
                templateUrl: "templates/article/edit-article.html"
            })
            .when("/admin/articles", {
                controller: "ArticlesCtrl",
                controllerAs: "articlesCtrl",
                templateUrl: "templates/article/articles.html"
            });
    }])
    .service("ArticleService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        this.find = function() {
            return $http.get(ApiBaseUri + "article/")
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.load = function(id) {
            return $http.get(ApiBaseUri + "article/" + id)
                .then(function(response) {
                    return response.data;
                })
                .then(function(article) {
                    article.createdDate = new Date(article.createdDate);
                    article.lastModifiedDate = new Date(article.lastModifiedDate);
                    return article;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.remove = function(id) {
            return $http.delete(ApiBaseUri + "article/" + id);
        };

        this.save = function(article) {
            var method = article._id ? "put" : "post";
            return $http({
                    method: method,
                    url: ApiBaseUri + "article/" + (article._id || ""),
                    data: article
                });
        };
    }])
    .controller("ArticleCtrl", ["$location", "$log", "$routeParams", "$scope", "ArticleService", function($location, $log, $routeParams, $scope, ArticleService) {
        $scope.alerts = [];

        // setup the default model.
        this.article = {
            body: "",
            published: false,
            tags: []
        };

        // if we were given an id on the url, load the record and populate the model.
        if ($routeParams.id) {
            ArticleService.load($routeParams.id)
                .then(function(article) {
                    this.article = article;
                }.bind(this));
        }

        this.save = function(article) {
            // flatten article.tags because of ngTagInput gayness.
            article.tags = article.tags.map(function(item) {
                return item.text;
            });

            ArticleService.save(article)
                .then(function(response) {
                    $location.path("/admin/articles");
                })
                .catch(function(response) {
                    $log.error(response);
                    $scope.alerts.push("failed to save article");
                });
        };
    }])
    .controller("ArticlesCtrl", ["$log", "ArticleService", function($log, ArticleService) {
        var self = this;

        this.articles = null;
        this.error = null;
        this.loading = false;

        function refresh() {
            self.loading = true;
            ArticleService.find()
                .then(function(articles) {
                    self.articles = articles;
                })
                .catch(function(err) {
                    $log.warn("failed to load articles", err);
                    self.error = err.message;
                })
                .finally(function() {
                    self.loading = false;
                })
        }

        this.remove = function(article) {
            ArticleService.remove(article._id)
                .then(function() {
                    refresh();
                })
                .catch(function(err) {
                    $log.warn("failed to remove article: " + article._id, err);
                    self.error = err.message;
                });
        };

        refresh();
    }])
    .controller("ArticleListItemCtrl", ["$scope", function($scope) {
        // TODO: any custom logic for interaction with the list item.
    }])
    .directive("articleListItem", [function() {
        return {
            scope: {
                item: "=item"
            },
            controller: "ArticleListItemCtrl",
            templateUrl: "templates/article/list-item-article.html"
        };
    }]);
