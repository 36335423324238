angular.module("hnAssociations", [])
    .service("AssociationsService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        this.find = function() {
            return $http.get(ApiBaseUri + "assoc/")
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };
    }]);
