angular.module("ngHatchTextAngular", ["ngHatchArtist", "ngHatchMedia", "ngTagsInput", "ui.bootstrap", "uiwPopover"])
    .config(["$provide", function($provide) {
        // intercept the creation of "taOptions", using "taRegisterTool" to register a need toolbar item. This can
        // be configured just like any other toolbar item.
        $provide.decorator("taOptions", ["taRegisterTool", "taToolFunctions", "$delegate", "$uibModal", function(taRegisterTool, taToolFunctions, taOptions, $modal) {
            taRegisterTool("uploadImage", {
                iconclass: "fa fa-image",
                tooltiptext: "Insert Media",
                action: function(deferred, restoreSelection) {
                    // show a model to enable the user to select/upload an image to insert into the document.
                    var modalInst = $modal.open({
                            controller: "hnInsertMediaModalInstance",
                            controllerAs: "$mediaModalCtrl",
                            templateUrl: "templates/wysiwyg/upload.html",
                            size: "lg hn-modal-maxheight",
                            scope: this
                        });

                    // the modal should be resolved with a URL for the image to be inserted...
                    modalInst.result
                        .then(function(result) {
                            // restore the selection in textAngular so that the image is inserted into the correct
                            // place.
                            restoreSelection();

                            // insert the image and resolve the promise.
                            document.execCommand('insertImage', true, result.uri);
                            deferred.resolve();
                        })
                        .catch(function() {
                            deferred.resolve();
                        });

                    return false;
                },
                onElementSelect: {
                    element: 'img',
                    action: taToolFunctions.imgOnSelectAction
                }
            });

            // setup the default toolbar.
            taOptions.toolbar = [['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
                ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
                ['html', 'insertLink', 'insertVideo', 'uploadImage']];

            return taOptions;
        }]);
    }])
    .run(["taTools", function(taTools) {
        // override toolbar icons
        taTools["clear"].iconclass = "fa fa-eraser";
    }])

    /**
     * @ngDoc directive
     * @name hnFileChooser
     * @description A wrapper for an input[type='file'] because the button cannot be styled by CSS. This directive
     * positions the native button off screen and binds a click handler to its element. As such this directive is
     * designed to be used with button elements and the like.
     */
    .directive("hnFileChooser", function() {
        return {
            scope: {
                "uploader": "="
            },
            link: function(scope, element, attrs) {
                var inputEl = element.find("input");

                element.on("click", function() {
                    inputEl[0].click();
                });
            },
            template: ['<input type="file" style="left: -5000px; position: absolute" nv-file-select="" uploader="uploader">',
                        '<ng-transclude></ng-transclude>'].join(""),
            transclude: true
        }
    })

    /**
     * @ngDoc controller
     * @name hnImageInfoCtrl
     * @description
     */
    .controller("hnImageInfoCtrl", ["$mediaItem", "$popupInstance", "ArtistService", "MediaService", function($mediaItem, $popupInstance, ArtistService, MediaService) {
        // take a copy of the mediaItem, so that changes are not persisted locally without hitting save.
        this.mediaItem = angular.copy($mediaItem);

        /**
         * @ngDoc method
         * @name loadArtists
         * @param query {String} the query string.
         * @returns {Promise} a promise for the list of matching artists.
         */
        this.loadArtists = function(query) {
            return ArtistService.find({"filter": query})
                .then(function(response) {
                    return response.results;
                }.bind(this));
        };

        /**
         * @ngDoc method
         * @name loadTags
         * @param query {String} the query string.
         * @returns {Promise} a promise for the list of matching tags.
         */
        this.loadTags = function(query) {
            // TODO: pass query string into media service.
            return MediaService.distinct("metadata.userData.tags")
                .then(function(results) {
                    return results;
                }.bind(this));
        };

        /**
         * @ngDoc method
         * @name save
         * @methodOf hnImageInfoCtrl
         * @description save changes to the "userData" property of the media item we're editing.
         */
        this.save = function() {
            var id = this.mediaItem.id,
                userData = angular.copy(this.mediaItem.userData);

            // flatten exhibition.tags because of ngTagInput limitations.
            userData.tags = (userData.tags || []).map(function(item) {
                return item.text;
            });

            MediaService.update(id, userData)
                .then(function(item) {
                    angular.extend($mediaItem.userData, item);
                    $popupInstance.close();
                });
        };
    }])

    /**
     * @ngDoc controller
     * @name hnMediaLocalCtrl
     * @description
     */
    .controller("hnMediaLocalCtrl", ["$scope", "uiwPopover", function($scope, uiwPopover) {
        this.imageInfo = function($event, mediaItem) {
            uiwPopover.show({
                controller: "hnImageInfoCtrl",
                controllerAs: "$ctrl",
                relativeTo: {
                    node: $event.target,
                    points: ["CR", "CL"]
                },
                resolve: {
                    "$mediaItem": mediaItem
                },
                templateUrl: "templates/hn/hn-media-info-popover.html",
                wrap: false,
                zIndex: 10000
            });
        };

        this.unlinkImage = function($event, mediaItem) {
            var mediaItems = $scope.mediaItems || [];
                idx = mediaItems.findIndex(function(item) {
                        return item.id === mediaItem.id;
                    });

            if (idx !== -1) {
                $scope.$emit("hnUnlinkMedia", mediaItem);
                mediaItems.splice(idx, 1);
            }
        };
    }])

    /**
     * @ngDoc controller
     * @name hnMediaGlobalCtrl
     * @description
     */
    .controller("hnMediaGlobalCtrl", ["$log", "$scope", "MediaService", "uiwPopover", function($log, $scope, MediaService, uiwPopover) {
        this.mediaItems = [];
        this.query = "";

        this.search = function() {
            $log.debug("search: '" + this.query + "'");

            MediaService.find()
                .then(function(items) {
                    items.forEach(function(item) {
                        item.uri = MediaService.link(item.id);
                        if (item.thumb) {
                            item.thumbUri = MediaService.thumbLink(item.id);
                        }
                    });

                    this.mediaItems = items;
                }.bind(this));
        };

        this.imageInfo = function($event, mediaItem) {
            uiwPopover.show({
                controller: "hnImageInfoCtrl",
                controllerAs: "$ctrl",
                relativeTo: {
                    node: $event.target,
                    points: ["CR", "CL"]
                },
                resolve: {
                    "$mediaItem": mediaItem
                },
                templateUrl: "templates/hn/hn-media-info-popover.html",
                wrap: false,
                zIndex: 10000
            });
        };
    }])

    /**
     * @ngDoc controller
     * @name hnMediaImportCtrl
     * @description
     */
    .controller("hnMediaImportCtrl", ["$scope", "ArtistService", "MediaService", function($scope, ArtistService, MediaService) {
        this.importHref = "";
        this.metaData = {};
        this.importing = false;

        /**
         * @ngDoc method
         * @name loadArtists
         * @param query {String} the query string.
         * @returns {Promise} a promise for the list of matching artists.
         */
        this.loadArtists = function(query) {
            return ArtistService.find({"filter": query})
                .then(function(response) {
                    return response.results;
                }.bind(this));
        };

        /**
         * @ngDoc method
         * @name loadTags
         * @param query {String} the query string.
         * @returns {Promise} a promise for the list of matching tags.
         */
        this.loadTags = function(query) {
            return MediaService.distinct("metadata.userData.tags")
                .then(function(results) {
                    return results;
                }.bind(this));
        };

        /**
         * @ngDoc method
         * @name import
         * @description trigger the import of the current href.
         */
        this.import = function() {
            var userData = angular.copy(this.metaData),
                importInst;

            // flatten exhibition.tags because of ngTagInput limitations.
            userData.tags = (userData.tags || []).map(function(item) {
                return item.text;
            });

            // trigger the import at the backend...
            importInst = MediaService.import(this.importHref, userData);
            this.importing = true;

            // when the import is complete, trigger the "hnUpdateComplete" event.
            importInst.result
                .then(function(model) {
                    // trigger the "hnIngestComplete" event and refresh the library.
                    $scope.$emit("hnIngestComplete", model);
                    $scope.refresh();
                })
                .finally(function() {
                    this.importing = false;
                }.bind(this));
        };
    }])

    /**
     * @ngDoc controller
     * @name hnInsertMediaModalInstance
     * @description the controller for the "insert media" dialog. This provides the interface for refreshing the image
     * list, inserting the image and handling the media upload.
     */
    .controller("hnInsertMediaModalInstance", ["$scope", "$uibModalInstance", "MediaService", function($scope, $modalInstance, MediaService) {
        // image selection.
        $scope.mediaItems = [];
        $scope.importHref = "";

        $scope.refresh = function() {
            // here we totally abuse the event mechanism by passing a function to the event handler which should be
            // invoked with the function that returns a promise for the data. This is essentially using the event
            // mechanism for two-way communication.
            $scope.$emit("hnMediaList", function(fn) {
                // invoke the function passed from the event handler. The function should return a promise for the
                // item data.
                fn().then(function(items) {
                        $scope.mediaItems = items;
                    })
                    .catch(function(err) {
                        console.error("failed to obtain media" + err);
                    });
            });
        };

        $scope.select = function($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.item = item;
        };

        // uploader.
        $scope.uploader = MediaService.uploader();

        // bind a callback to emit a "hnIngestComplete" event when a file is uploaded.
        $scope.uploader.onCompleteItem = function(item, response, status, headers) {
            $scope.$emit("hnIngestComplete", response);
        };

        // bind a callback to refresh the media list when all the uploads are complete.
        $scope.uploader.onCompleteAll = function() {
            $scope.refresh();
        };

        // insert the media.
        $scope.insert = function() {
            $scope.$emit("hnInsertMedia", $scope.item);

            $modalInstance.close($scope.item);
        };

        $scope.refresh();
    }])

    /**
     * @ngDoc directive
     * @name hnEditor
     * @description a rich text editor. This is currently a wrapper for the text-angular directive. TextAngular does
     * not play nice in terms of extensions, so it was necessary to wrap the element to encapsulate all the additional
     * behaviour around uploading media.
     */
    .directive("hnEditor", function() {
        return {
            link: function(scope, element, attrs) {
                // listen for the "hnIngestComplete" event. This is emitted on successful completion of a file upload
                // on the insert media dialog.
                scope.$on("hnIngestComplete", function(ev, response) {
                    // trigger the expression that may have been provided in the "on-ingest-complete" attribute. This
                    // will be able to take the "$response" local containing the HTTP response of the upload.
                    scope.onIngestComplete({"$response": response});
                });

                // hook the hnMediaList event and invoke the "mediaList" expression as passed in the "mediaList"
                // attribute on the directive element.
                scope.$on("hnMediaList", function(ev, invoke) {
                    invoke(scope.mediaList);
                });

                // hook the hnInsertMedia event and invoke the "onInsertMedia" expression passed in the
                // "on-insert-media" attribute of the directive element.
                scope.$on("hnInsertMedia", function(ev, mediaItem) {
                    scope.onInsertMedia({"$mediaItem": mediaItem});
                });

                // hook the hnUnlinkMedia event and invoke the "onUnlinkMedia" expression passed in the
                // "on-unlink-media" attribute of the directive element.
                scope.$on("hnUnlinkMedia", function(ev, mediaItem) {
                    scope.onUnlinkMedia({"$mediaItem": mediaItem});
                });
            },
            scope: {
                "value": "=ngModel",
                "mediaList": "&mediaList",
                "onIngestComplete": "&onIngestComplete",
                "onInsertMedia": "&onInsertMedia",
                "onUnlinkMedia": "&onUnlinkMedia"
            },
            template: '<text-angular ng-model="value"></text-angular>'
        }
    });
