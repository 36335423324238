angular.module("ngHatchArtist", ["rt.debounce", "dialogs.main", "hnDatePicker", "ngHatchUtils", "ngRoute", "ngTagsInput", "uiSwitch", "ui.bootstrap", "uiw-rating", "uiw-sort-control", "uiwidgets-ng-tpls", "hnDateOverride"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/artists/create", {
                controller: "ArtistCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_CREATE_ARTIST",
                templateUrl: "templates/artist/edit-artist.html"
            })
            .when("/admin/artists/:id", {
                controller: "ArtistCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_UPDATE_ARTIST",
                templateUrl: "templates/artist/edit-artist.html"
            })
            .when("/admin/artists", {
                controller: "ArtistsCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_ADMIN",
                reloadOnSearch: false,
                templateUrl: "templates/artist/artists.html"
            });
    }])
    .service("ArtistService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        this.find = function(opts) {
            const index = opts.index || "fullName";

            var queryOpts = {};

            // transform the incoming find options into the form required on the url in order to send them with
            // the request.
            opts.filter && (queryOpts.q = $ngHatchUtils.getFilterQueryParam(index, q));
            opts.offset && (queryOpts.offset = opts.offset);
            angular.isUndefined(opts.limit) || (queryOpts.limit = opts.limit);
            opts.sort && (queryOpts.sort = opts.sort);

            // transfer any facets to http parameters on the url.
            angular.forEach(opts.facets, function(value, facet) {
                queryOpts[facet] = value;
            });

            return $http.get(ApiBaseUri + "artist/", {params: queryOpts})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };

        this.load = function(id) {
            return $http.get(ApiBaseUri + "artist/" + id)
                .then(function(response) {
                    return response.data;
                })
                .then(function(artist) {
                    artist.createdDate = new Date(artist.createdDate);
                    artist.lastModifiedDate = new Date(artist.lastModifiedDate);
                    return artist;
                });
        };

        this.remove = function(id) {
            return $http.delete(ApiBaseUri + "artist/" + id);
        };

        this.save = function(artist) {
            var method = artist._id ? "put" : "post";
            return $http({
                    method: method,
                    url: ApiBaseUri + "artist/" + (artist._id || ""),
                    data: artist
                });
        };
    }])
    /**
     * @ngDoc controller
     * @name ArtistCtrl
     * @description the controller for the edit/create Artist screen.
     */
    .controller("ArtistCtrl", ["$location", "$log", "$ngHatchState", "$routeParams", "$scope", "ArtistService", function($location, $log, $ngHatchState, $routeParams, $scope, ArtistService) {
        $scope.alerts = [];

        // setup the default model.
        this.artist = {
            body: "",
            tags: []
        };

        // if we were given an id on the url, load the record and populate the model.
        if ($routeParams.id) {
            ArtistService.load($routeParams.id)
                .then(function(artist) {
                    this.artist = artist;
                }.bind(this));
        }

        this.toolbar = [['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
            ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
            ['html', 'insertImage','insertLink', 'insertVideo', 'uploadImage']];

        /**
         * @ngDoc method
         * @name cancel
         * @methodOf ArtistCtrl
         * @description cancel editing the record. All changes will be lost and the user will be returned to the
         * Artists list.
         */
        this.cancel = function() {
            if ($scope.form.$dirty) {
                $log.debug("TODO: confirm");
            }

            $ngHatchState.location("/admin/artists");
        };

        /**
         * @ngDoc method
         * @name save
         * @methodOf ArtistCtrl
         * @param artist {Object} the Artist model.
         * @description save the Artist model.
         */
        this.save = function(artist) {
            // flatten artist.aliases because of ngTagInput limitations.
            artist.aliases = (artist.aliases || []).map(function(item) {
                return item.text;
            });

            // if the form is not valid, don't send the save request...
            if ($scope.form.$invalid) {
                return;
            }

            // if the lastModifiedDate has not been changed, then update the lastModifiedDate to now.
            if (!$scope.form.lastModifiedDate.$dirty) {
                artist.lastModifiedDate = Date.now();
            }

            ArtistService.save(artist)
                .then(function(response) {
                    $ngHatchState.location("/admin/artists");
                })
                .catch(function(response) {
                    $log.error(response);
                    $scope.alerts.push("failed to save Artist");
                });
        };
    }])
    .controller("ArtistsCtrl", ["$location", "$log", "$ngHatchState", "$scope", "ArtistService", "debounce", "dialogs", function($location, $log, $ngHatchState, $scope, ArtistService, debounce, dialogs) {
        this.error = null;
        this.loading = false;

        this.artists = [];

        // figure out the correct state for the page. The state may be obtained from the state store if it had
        // previously been stored, or derived from the url
        this.state = angular.extend({page: 1, q: ""}, $location.search());

        // derive the pageSize from the state, or use a default.
        this.pageSize = this.state.l || 10;

        // get a list of indexes available.
        this.indexes = [{"key": "fullName", "label": "Full Name"}];

        // extract any facets from the url into the facets object.
        this.facets = {};
        angular.forEach(this.state, function(value, key) {
            if (key.substr(0, 3) === "fa_") {
                this.facets[key] = value;
            }
        }.bind(this));

        // set an artificial total in the interim, or the paginator will reset the page to 1.
        this.total = this.pageSize * this.state.page;

        // react to the url changing. Update the state model and hit refresh to fetch the data.
        $scope.$on("$routeUpdate", function() {
            this.state = $location.search();
            this.refresh();
        }.bind(this));

        this.refresh = function() {
            var opts = {
                    filter: this.state.q,
                    index: this.state.i,
                    offset: (this.state.page - 1) * this.pageSize,
                    limit: this.pageSize,
                    sort: this.state.sort,
                    facets: this.facets
                };

            ArtistService.find(opts)
                .then(function(data) {
                    this.artists = data.results;
                    this.total = data.total;
                }.bind(this))
                .catch(function(err) {
                    $log.warn("failed to load data", err);
                    this.error = err.message;
                }.bind(this))
                .finally(function() {
                    this.loading = false;
                }.bind(this));
        };

        this.remove = function(artist) {
            var dialog = dialogs.confirm("Delete Artist", "Are you sure you want to delete this Artist?"),
                self = this;

            dialog.result
                .then(function() {
                    ArtistService.remove(artist._id)
                        .then(function() {
                            self.refresh();
                        }.bind(this))
                            .catch(function() {
                                // TODO: handle failure to remove record.
                            });
                })
        }.bind(this);

        /**
         * @ngDoc method
         * @name storeState
         * @methodOf ArtistsCtrl
         * @description store the current page state in the store. This is invoked prior to navigation to pages where
         * we may want to return form. When using with anchor tags, calling this via ng-click will have the state stored
         * before the browser location is updated.
         */
        this.storeState = function() {
            $ngHatchState.location();
        }.bind(this);

        this.filter = debounce(120, function() {
            $location.search(this.state);
        }.bind(this));

        this.pageChange = function() {
            $location.search(this.state);
        }.bind(this);

        this.changeIndex = function(index) {
            this.index = index;
            this.state.i = index.key;

            // only re-search if there is query text.
            if (this.state.q) {
                $location.search(this.state);
            }
        }.bind(this);

        this.changeFacets = function(index) {
            $location.search(this.state);
        }.bind(this);

        /**
         * @ngDoc method
         * @name changePageSize
         * @methodOf ArtistsCtrl
         * @description invoked when the page size is changed. Used to manipulate the state object, by setting
         * or deleting the "l" property. After the state has been updated, the url updated.
         */
        this.changePageSize = function() {
            if (this.pageSize !== 10) {
                this.state.l = this.pageSize;
            } else {
                delete this.state.l;
            }
            $location.search(this.state);
        }.bind(this);

        this.sortBy = function() {
            $location.search(this.state);
        }.bind(this);

        this.refresh();
    }])
    .controller("ArtistListItemCtrl", ["$scope", function($scope) {
        // TODO: any custom logic for interaction with the list item.
    }])
    .directive("artistListItem", [function() {
        return {
            scope: {
                item: "=item"
            },
            controller: "ArtistListItemCtrl",
            templateUrl: "templates//list-item-artist.html"
        };
    }]);
