angular.module("hnExternalLink", ["dialogs.main"])
    /**
     * @ngDoc controller
     * @name ExternalLinkDialogCtrl
     * @description
     */
    .controller("ExternalLinkDialogCtrl", ["$link", "$uibModalInstance", "ApiBaseUri", "FileUploader", "hnAuth", function($link, $modalInstance, ApiBaseUri, FileUploader, hnAuth) {
        this.uploader = new FileUploader({queueLimit: 1, url: ApiBaseUri + "media/"});

        this.uploader.onBeforeUploadItem = function(item) {
            hnAuth.applyAuthHeader(function(headerName, headerValue) {
                item.headers[headerName] = headerValue;
            });
        };

        // bind a callback to be called when an upload is complete.
        this.uploader.onCompleteItem = function(item, response, status, headers) {
            this.link.imageId = response.id;
            this.imageUrl = ApiBaseUri + "media/" + response.id;
        }.bind(this);

        this.upload = function() {
            this.uploader.uploadAll();
        };

        // copy the link so that modifications are only persisted on submit.
        this.link = $link ? angular.copy($link) : {};
        this.imageUrl = $link.imageId ? ApiBaseUri + "media/" + $link.imageId : null;
    }])

    /**
     * @ngDoc directive
     * @name externalLinkTable
     * @description the table of external links, including edit/delete buttons.
     */
    .directive("externalLinkTable", [function() {
        return {
            bindToController: true,
            controller: ["dialogs", "$uibModal", function(dialogs, $uibModal) {
                /**
                 * @ngDoc method
                 * @name linkDialog
                 * @methodOf ExhibitionCtrl
                 * @param [link] {Object} the link object to edit.
                 * @param [index] {Number} the index of the event in the set of events.
                 */
                this.linkDialog = function(link, index) {
                    var isNew = !link,
                        modalInst = $uibModal.open({
                            bindToController: true,
                            controller: "ExternalLinkDialogCtrl",
                            controllerAs: "$ctrl",
                            templateUrl: "templates/external-link/external-link-dialog.html",
                            size: "lg hn-modal-maxheight",
                            resolve: {
                                "$link": isNew ? {} : this.links[index]
                            }
                        });

                    modalInst.result
                        .then(function(link) {
                            this.links || (this.links = {});

                            return this.onUpdate({$link: link, $index: index});
                        }.bind(this))
                        .then(function(link) {
                            // NOTE: index is null if we're creating.
                            angular.isUndefined(index) ?  this.links.push(link) : this.links[index] = link;
                        }.bind(this));
                };

                /**
                 * @ngDoc method
                 * @name removeLink
                 * @methodOf ExhibitionCtrl
                 * @param link {Object} the link object to remove.
                 * @param index {Number} the index of the link in the list.
                 */
                this.removeLink = function(link, index) {
                    var dialog = dialogs.confirm("Delete External Link", "Are you sure you want to delete this external link?");

                    dialog.result
                        .then(function() {
                            return this.onDelete({$link: link, $index: index});
                        }.bind(this))
                        .then(function() {
                            this.links.splice(index, 1);
                        }.bind(this));
                };
            }],
            controllerAs: "$ctrl",
            scope: {
                "links": "=",
                "onDelete": "&",
                "onUpdate": "&"
            },
            templateUrl: "templates/external-link/external-link-table.html"
        }
    }]);
