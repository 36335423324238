angular.module("hnStaticPages", ["ngHatchUtils", "ngRoute"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/pages", {
                controller: "PagesCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/pages/pages.html"
            })
            .when("/admin/pages/create", {
                controller: "PageCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_CREATE_PAGE",
                templateUrl: "templates/pages/edit-page.html"
            })
            .when("/admin/pages/:id", {
                controller: "PageViewCtrl",
                controllerAs: "$ctrl",
                permissions: "CAN_ADMIN",
                templateUrl: "templates/pages/view-page.html"
            })
            .when("/admin/pages/:id/edit", {
                controller: "PageCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_UPDATE_PAGE",
                templateUrl: "templates/pages/edit-page.html"
            })
            .when("/admin/pages/:id/edit/:version", {
                controller: "PageCtrl",
                controllerAs: "ctrl",
                permissions: "CAN_UPDATE_PAGE_VERSION",
                templateUrl: "templates/pages/edit-page-version.html"
            })
    }])
    .service("PageService", ["$http", "$ngHatchUtils", "ApiBaseUri", function($http, $ngHatchUtils, ApiBaseUri) {
        this.find = function(opts) {
            var queryOpts = {};
            return $http.get(ApiBaseUri + "page/", {params: queryOpts})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    $ngHatchUtils.httpError(response);
                });
        };
        
        this.load = function(id) {
            return $http.get(ApiBaseUri + "page/" + id)
                .then(function(response) {
                    return response.data;
                });
        };

        this.remove = function(pageId, versionId) {
            var uri = ApiBaseUri + "page/" + pageId;
            if (versionId) {
                uri+= "/version/" + versionId;
            }
            
            return $http.delete(uri)
                .catch(function(response) {
                    throw new Error(response.data.message);
                });
        };

        /**
         * 
         * @param page {Object} the page object.
         *   @param _id {String} the page id.
         *   @param srcPath {String} the path for the page.
         * @param [version] {Object} the version object.
         *   @param version.content {String} the page content.
         *   @param version.images {Array} an array of media ids.
         *   @param version.title {String} the page title.
         * @returns {Promise} a promise for the saved page record.
         */
        this.save = function(page, version) {
            var method = page._id ? "put" : "post",
                urlParts = ["page", page._id || ""],
                payload = {page: page},
                opts;
            
            if (version) {
                payload.version = version;
            }

            opts = {
                method: method,
                url: ApiBaseUri + urlParts.join("/"),
                data: payload
            };

            return $http(opts)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(response) {
                    throw new Error(response.data.message);
                });
        };
    }])
    .controller("PageViewCtrl", ["$routeParams", "PageService", function($routeParams, PageService) {
        var pageId = $routeParams.id;

        this.page = {
            versions: []
        };

        this.copy = function(versionId) {
            PageService.load(pageId)
                .then(function(page) {
                    var source = page.versions.find(function(version) { return version._id == versionId; }),
                        target = angular.extend(source);

                    delete target._id;
                    return PageService.save({_id: pageId}, target);
                })
                .then(function(page) {
                    this.page = page;
                }.bind(this));
        };

        this.remove = function(versionId) {
            PageService.remove(pageId, versionId)
                .then(function() {
                    var index = this.page.versions.findIndex(function(version) {
                            return version._id === versionId;
                        });

                    if (index !== -1) {
                        this.page.versions.splice(index, 1);
                    }
                }.bind(this));
        };

        this.setCurrentVersion = function(versionId) {
            var model = {
                    _id: this.page._id,
                    currentVersion: versionId
                };

            PageService.save(model)
                .then(function() {
                    this.page.currentVersion = versionId;
                }.bind(this));
        };

        PageService.load(pageId)
            .then(function(page) {
                this.page = page;
            }.bind(this));
    }])
    .controller("PageCtrl", ["$log", "$ngHatchState", "$q", "$routeParams", "$scope", "MediaService", "PageService", function($log, $ngHatchState, $q, $routeParams, $scope, MediaService, PageService) {
        var pageId = $routeParams.id,
            versionId = $routeParams.version;

        this.alerts = [];

        this.page = {
            title: "",
            images: []
        };

        // if we were given an id on the url, load the record and populate the model.
        if (pageId) {
            PageService.load(pageId)
                .then(function(page) {
                    // if there are no page versions, we've got nothing to do!
                    if (!page.versions || !page.versions.length) {
                        return;
                    }

                    var pageVersion = versionId
                            ? page.versions.find(function(version) { return version._id === versionId; })
                            : page.versions[page.versions.length-1];

                    Object.assign(this.page, {
                        _id: page._id,
                        title: pageVersion.title,
                        path: page.path,
                        content: pageVersion.content
                    });
                }.bind(this))
        }

        /**
         * @ngDoc method
         * @name save
         * @methodOf PageCtrl
         * @param model {Object} the page model.
         * @description save the page model.
         */
        this.save = function(model) {
            // if the form is not valid, don't send the save request...
            if ($scope.form.$invalid) {
                return;
            }

            var page = {_id: pageId, srcPath: model.path};
            var version = {_id: versionId, title: model.title, content: model.content, images: model.images};

            this.alerts = [];

            PageService.save(page, version)
                .then(function(page) {
                    $ngHatchState.location("/admin/pages/" + page._id);
                })
                .catch(function(err) {
                    $log.error(err);
                    this.alerts.push({message: err.message, type: "danger"});
                }.bind(this));

        };

        /**
         * @ngDoc method
         * @name cancel
         * @methodOf PageCtrl
         * @description cancel editing the record. All changes will be lost and the user will be returned to the
         * Pages list.
         */
        this.cancel = function() {
            if ($scope.form.$dirty) {
                $log.debug("TODO: confirm");
            }

            $ngHatchState.location("/admin/pages/" + pageId);
        };

        /**
         * @ngDoc method
         * @name mediaList
         * @methodOf PageCtrl
         * @returns {Promise} a promise for the media list.
         * @description get the media list for the page record.
         */
        this.mediaList = function() {
            var images = this.page.images || [],
                results = [];

            if (images.length) {
                results = MediaService.find({where: {"_id": {"$in": images}}})
                    .then(function(items) {
                        items.forEach(function(item) {
                            item.uri = MediaService.link(item.id);
                            if (item.thumb) {
                                item.thumbUri = MediaService.thumbLink(item.id);
                            }
                        });
                        return items;
                    });
            }

            return $q.when(results);
        };

        /**
         * @ngDoc method
         * @name onIngestComplete
         * @methodOf ExhibitionCtrl
         * @param mediaItem {Object} the ingested media item.
         * @description triggered when an image is uploaded via the rich text editor.
         */
        this.onIngestComplete = function(mediaItem) {
            // add the image id to the list of images bound to this record.
            var images = this.page.images || (this.page.images = []);
            images.push(mediaItem.id);
        };

        /**
         * @ngDoc method
         * @name onInsertMedia
         * @param mediaItem {Object} the inserted media item
         * @description triggered when an image is inserted via the insert media modal.
         */
        this.onInsertMedia = function(mediaItem) {
            // add the image id to the list of images bound to this record.
            var images = this.page.images || (this.page.images = []),
                idx = images.indexOf(mediaItem.id);

            if (idx === -1) {
                images.push(mediaItem.id);
            }
        };
    }])
    .controller("PagesCtrl", ["$log", "PageService", "dialogs", function($log, PageService, dialogs) {
        this.error = null;
        this.loading = false;

        this.alerts = [];
        this.pages = [];

        this.refresh = function() {
            var opts = {};

            PageService.find(opts)
                .then(function(data) {
                    this.pages = data.results.map(function(result) {
                        var versions = result.versions || [],
                            current = versions.find(function(version) {
                                return version._id == result.currentVersion;
                            }) || {};
                        result.title = current.title;
                        result.lastModifiedDate = current.lastModifiedDate;
                        return result;
                    });
                    this.total = data.total;
                }.bind(this))
                .catch(function(err) {
                    $log.warn("failed to get page data", err);
                    this.error = err.message;
                }.bind(this))
                .finally(function() {
                    this.loading = false;
                }.bind(this));

            this.loading = true;
        }.bind(this);

        /**
         * @ngDoc method
         * @name remove
         * @methodOf PageCtrl
         * @param page {Object} the page model.
         * @description delete the page model, prompting for confirmation first.
         */
        this.remove = function(page) {
            var dialog = dialogs.confirm("Delete Page", "Are you sure you want to delete this page?"),
                self = this;

            dialog.result.then(function() {
                PageService.remove(page._id)
                    .then(function() {
                        self.refresh();
                    })
                    .catch(function(err) {
                        $log.error(err);
                        this.alerts.push("failed to save page");
                    }.bind(this));
            }.bind(this));
        };

        this.refresh();
    }]);