"use strict";

angular.module("ngHatchUtils", ["dialogs.main"])
    /**
     * @ngDoc factory
     * @name $hnDirtyNag
     * @param [checkFn] {Function} an optional function that defines a check to determine if the dialog should appear.
     * @return {Function} a function to cancel the location change listener.
     * @description register a $locationChangeStart event handler to trigger a dialog, warning the user that navigating
     * away from the current view may result in the loss of unsaved changes on the page.
     */
    .factory("$hnDirtyNag", ["$location", "$rootScope", "dialogs", function($location, $rootScope, dialogs) {
        return function(checkFn) {
            var stopNag;

            stopNag = $rootScope.$on("$locationChangeStart", function(event) {
                var hash = $location.hash(),
                    path = $location.path(),
                    search = $location.search();

                // if the form is dirty, we'll prompt the user.
                if (!checkFn || checkFn()) {
                    // prevent the default behaviour so the route doesn't change... yet.
                    event.preventDefault();

                    // open the confirm dialog.
                    dialogs.confirm("Abandon Changes?", "You have unsaved changes, are you sure you want to lose them?")
                        .result
                        .then(function() {
                            $location
                                .path(path)
                                .search(search)
                                .hash(hash);

                            // we don't want the listener to be triggered again, so cancel it.
                            stopNag();
                        });
                }
            });

            return stopNag;
        };
    }])

    /**
     * @ngDoc factory
     * @name $hnFormErrorMessage
     * @param $form the FormController instance
     * @return {String} a string to be used as the body of uibAlert
     * @description generate a form error message template to be used with uibAlert.
     */
    .factory("$hnFormErrorMessage", [function() {
        return function $hnFormErrorMessage($form) {
            var msg = "Invalid Form";

            if ($form.$error) {
                msg+= '<ul>';
                angular.forEach($form.$error, function(fields, validatorKey) {
                    angular.forEach(fields, function(field) {
                        msg+= '<li title="parser: \'' + validatorKey + '\', modelValue: \'' + field.$modelValue + '\'">' + (field.$name || 'Unnamed') + '</li>';
                    });
                });
                msg+= '</ul>';
            }

            return msg;
        };
    }])

    .service("$ngHatchUtils", [function() {
        this.httpError = function(response) {
            var contentType, data;
            if (response.status === -1) {
                // untrusted certificate?
                throw new Error("service error, untrusted certificate");
            }

            // attempt to resolve the content-type  , stripping off any character encoding where possible.
            contentType = response.headers("Content-Type");
            if (contentType) {
                let bits = contentType.split(/\s*;\s*/);
                contentType = bits[0].trim();
            }

            // if we've got a JSON response, look for a "message" property.
            if (contentType === "application/json") {
                let message = response.data && response.data.message;
                throw new Error(message);
            }

            throw new Error("service error: " + response.statusText);
        };

        this.getFilterQueryParam = function(index, q) {
            // escape any "'" (single quote) characters to be passed in the string for the `contains` function.
            return `${index}.contains('${q.replace("'", "\\'")}', true)`
        };

        this.parseFuzzyDate = function(component) {
            if (!component) {
                return;
            }

            component.start && (component.start = new Date(component.start));
            component.end && (component.end = new Date(component.end));
        };
    }])

    /**
     * @ngDoc service
     * @name $ngHatchState
     * @description store/restore page state objects. This is a tool for maintaining state between pages. For example,
     * restoring the state of a search results page after looking at a specific record page.
     */
    .service("$ngHatchState", ["$location", "$log", "$rootScope", function($location, $log, $rootScope) {
        var state = $rootScope.$ngHatchState || ($rootScope.$ngHatchState = {});

        /**
         * @ngDoc method
         * @name clear
         * @param [key] {String} Optional. a specific key to clear state for.
         * @description clear all state from the store.
         */
        this.clear = function(key) {
            if (key) {
                delete state[key];
                return;
            }

            state = {};
        };

        /**
         * @ngDoc method
         * @name location
         * @methodOf $ngHatchState
         * @param [path] Optional. the path we seek state for.
         * @description store/restore the search portion of the location for the current or given path. This can be used
         * to store / restore url state for a path.
         */
        this.location = function(path) {
            var val;

            if (path) {
                val = this.restore(path);
                $location.path(path);
                val && $location.search(val);
                $log.debug('unstashed state: {path: "' + path + '", search: "' + val + '"}');
                return;
            }

            path = $location.path();
            state[path] = $location.search();
            $log.debug('stashed state: {path: "' + path + '", search: "' + state[path] + '"}');
        }.bind(this);

        /**
         * @ngDoc method
         * @name restore
         * @param key {String} the key to restore the state from.
         * @returns {Object} the state object or undefined.
         * @description retrieve state and clear the state from the store.
         */
        this.restore = function(key) {
            var val = state[key];
            val && delete state[key];
            return val;
        };

        /**
         * @ngDoc method
         * @param key {String} the key to store state under.
         * @param val {Object} the state object.
         * @description store state in the store.
         */
        this.store = function(key, val) {
            state[key] = val;
        };
    }])

    /**
     * @ngDoc directive
     * @name toNumber
     * @description take the value from ng-model and convert to to/from a number. This is particularly useful when
     * dealing with select/option tags.
     */
    .directive("toNumber", function() {
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function(val) {
                    return val ? parseInt(val, 10) : null;
                });
                ngModel.$formatters.push(function(val) {
                    return val ? '' + val : null;
                });
            }
        };
    });
