"use strict";

angular.module("hnSettings", ["ngRoute"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/settings", {
                controller: "SettingsCtrl",
                controllerAs: "$ctrl",
                permissions: "CAN_SETTINGS",
                templateUrl: "templates/settings.html"
            });
    }])
    .controller("SettingsCtrl", ["$http", "ApiBaseUri", function($http, ApiBaseUri) {
        this.alerts = [];
        this.buildingSearchIndex = false;

        this.rebuildSearchIndex = function() {
            this.alerts = [];
            this.buildingSearchIndex = true;
            $http.get(ApiBaseUri + "search/rebuildIndex")
                .then(function(response) {
                    if (response.status === 200) {
                        this.alerts.push({type: "success", message: "Successfully rebuilt search index"});
                    }
                }.bind(this))
                .finally(function() {
                    this.buildingSearchIndex = false;
                }.bind(this));
        };
    }]);
