angular.module("hnDateOverride", ["uiwDatePicker"])
    .directive("hnDateOverride", [function() {
        return {
            controller: ["$scope", function($scope) {
                $scope.override = false;
            }],
            link: function(scope, element, attrs, ngModelCtrl) {
                if (ngModelCtrl) {
                    ngModelCtrl.$render = function() {
                        scope.value = ngModelCtrl.$viewValue;
                    };

                    scope.$watch("value", function(newVal) {
                        ngModelCtrl.$setViewValue(newVal);
                    });
                }
            },
            require: "?ngModel",
            restrict: "AE",
            scope: {},
            templateUrl: "templates/hn/hn-date-override.html"
        }
    }]);