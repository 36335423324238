"use strict";

angular.module("hnFrontPage", ["ngHatchExhibition", "ngHatchMedia", "ngHatchMuseum", "ngRoute", "ui.bootstrap", "ui.select"])
    .config(["$routeProvider", function($routeProvider) {
        $routeProvider
            .when("/admin/front-page", {
                controller: "FrontPageCtrl",
                controllerAs: "$ctrl",
                templateUrl: "templates/front-page/front-page.html"
            });
    }])

    /**
     * @ngDoc controller
     * @name FrontPageCtrl
     * @description
     */
    .controller("FrontPageCtrl", ["ConfigService", "ExhibitionService", "MuseumService", "$uibModal", function(ConfigService, ExhibitionService, MuseumService, $uibModal) {
        this.alerts = [];

        this.carouselMuseums = [];
        this.features = [null, null, null, null, null, null];

        ConfigService.load("frontPage")
            .then(props => {
                props || (props = {});
                props.carouselMuseums || (props.carouselMuseums = []);
                props.features || (props.features = [null, null, null, null, null, null]);

                // migrate the legacy `featuredExhibitions` property if it exists.
                if (props.featuredExhibitions) {
                    for(let f=0; f<props.featuredExhibitions.length && f < props.features.length; f++) {
                        const id = props.featuredExhibitions[f];
                        id && (props.features[f] = {type: "exhibition", id});
                    }
                    delete props.featuredExhibitions;
                }

                // extract an array of exhibition ids.
                const exhibitionIds = props.features.reduce((exhibitionIds, feature) => {
                    if (feature && feature.type === "exhibition") {
                        exhibitionIds.push(feature.id);
                    }
                    return exhibitionIds;
                }, []);

                let snippets = props.snippets || {};
                this.topSection = snippets.topSection || {content: "", images: []};
                this.lowerSection = snippets.lowerSection || {content: "", images: []};

                const museumIds = props.features.reduce((museumIds, feature) => {
                    if (feature && feature.type === "museum") {
                        museumIds.push(feature.id);
                    }
                    return museumIds;
                }, [...props.carouselMuseums]);

                MuseumService.find({where: {_id: {$in: museumIds}}, limit: 0})
                    .then(data => {
                        const results = data.results || [];
                        const hash = results.reduce((hash, museum) => {
                                hash[museum._id] = museum;
                                return hash;
                            }, {});

                        this.carouselMuseums = props.carouselMuseums.map(id => hash[id]);
                        for (let f=0; f<this.features.length; f++) {
                            const feature = props.features[f];
                            if (feature && feature.type === "museum") {
                                this.features[f] = {
                                    ...feature,
                                    entity: hash[feature.id]
                                };
                            }
                        }
                    });

                ExhibitionService.find({where: {_id: {$in: exhibitionIds}}, limit: 0})
                    .then(data => {
                        const results = data.results || [];
                        const hash = results.reduce((hash, exhibition) => {
                                hash[exhibition._id] = exhibition;
                                return hash;
                            }, {});

                        for (let f=0; f<this.features.length; f++) {
                            const feature = props.features[f];
                            if (feature && feature.type === "exhibition") {
                                this.features[f] = {
                                    ...feature,
                                    entity: hash[feature.id]
                                };
                            }
                        }
                    });
            });

        /**
         * @ngDoc method
         * @name addCarouselMuseumDialog
         * @methodOf FrontPageCtrl
         */
        this.addCarouselMuseumDialog = function() {
            var modalInst = $uibModal.open({
                    controller: "MuseumSelectDialogCtrl",
                    controllerAs: "$ctrl",
                    templateUrl: "templates/front-page/museum-select-dialog.html",
                    resolve: {
                        "$museum": null
                    }
                });

            modalInst.result
                .then(function(museum) {
                    this.carouselMuseums.push(museum);
                }.bind(this));
        };

        this.editSnippetDialog = function(key) {
            var modalInst = $uibModal.open({
                    controller: "EditSnippetDialogCtrl",
                    controllerAs: "$ctrl",
                    templateUrl: "templates/front-page/edit-snippet-dialog.html",
                    resolve: {
                        "$snippet": () => {
                            return angular.extend({}, this[key]);
                        }
                    }
                });

            modalInst.result
                .then(($snippet) => {
                    this[key] = $snippet;
                });
        };

        /**
         * @ngDoc method
         * @name exhibitionSelectDialog
         * @methodOf FrontPageCtrl
         * @param index {Number} the index of the featured exhibition.
         */
        this.exhibitionSelectDialog = function(index) {
            var modalInst = $uibModal.open({
                    controller: "ExhibitionSelectDialogCtrl",
                    controllerAs: "$ctrl",
                    templateUrl: "templates/front-page/exhibition-select-dialog.html",
                    resolve: {
                        "feature": this.features[index]
                    }
                });

            modalInst.result
                .then(feature => {
                    this.features[index] = feature;
                });
        };

        /**
         * @ngDoc method
         * @name clearFeature
         * @methodOf FrontPageCtrl
         * @param index {Number} the index to clear
         */
        this.clearFeature = function(index) {
            this.features[index] = null;
        };

        /**
         * @ngDoc method
         * @name removeCarouselMuseum
         * @methodOf FrontPageCtrl
         * @param exhibition {Object} the museum to remove from the list.
         */
        this.removeCarouselMuseum = function(museum) {
            var index = this.carouselMuseums.findIndex(function(item) {
                    return item._id === museum._id;
                });

            if (index !== -1) {
                this.carouselMuseums.splice(index, 1);
            }
        };

        /**
         * @ngDoc method
         * @name save
         * @methodOf FrontPageCtrl
         */
        this.save = function() {
            var props = {
                    carouselMuseums: this.carouselMuseums.map((museum) => {return museum._id}),
                    features: this.features.map(feature => {
                        if (feature) {
                            const { entity, ...otherProps } = feature;
                            return otherProps;   
                        }
                        return null;
                    }),
                    snippets: {
                        topSection: this.topSection,
                        lowerSection: this.lowerSection
                    }
                };

            ConfigService.save("frontPage", props)
                .then(function() {
                    this.alerts = [{type: "info", message: "Saved configuration."}];
                }.bind(this))
                .catch(function(err) {
                    this.alerts = [{type: "danger", message: "An unexpected error occurred."}];
                }.bind(this));
        };
    }])

    /**
     * @ngDoc controller
     * @name ExhibitionSelectDialogCtrl
     * @description
     */
    .controller("ExhibitionSelectDialogCtrl", ["$uibModalInstance", "ExhibitionService", "MuseumService", "feature", function($uibModalInstance, ExhibitionService, MuseumService, feature) {
        this.exhibition = null;
        this.exhibitions = [];
        this.museum = null;
        this.museums = [];
        this.typeBool = !!(feature && feature.type === "exhibition");

        this.updateType = () => {
            this.type = this.typeBool ? "exhibition" : "museum";
        };

        this.refreshExhibitions = function(search) {
            return ExhibitionService.find({"filter": search, "fields": ["_id", "title"], "limit": 300})
                .then(data => {
                    this.exhibitions = data.results;
                });
        };

        this.refreshMuseums = function(search) {
            return MuseumService.find({"filter": search, "fields": ["_id", "name"], "limit": 300})
                .then(data => {
                    this.museums = data.results;
                });
        };

        this.onSubmit = () => {
            const entity = this.type === "museum" ? this.museum : this.exhibition;
            $uibModalInstance.close({
                type: this.type,
                entity,
                id: entity._id
            });
        };

        this.updateType();
    }])

    /**
     * @ngDoc controller
     * @name MuseumSelectDialogCtrl
     * @description
     */
    .controller("MuseumSelectDialogCtrl", ["MuseumService", function(MuseumService) {
        this.museum = null;
        this.museums = [];

        this.refreshMuseums = function(search) {
            return MuseumService.find({"filter": search})
                .then(function(data) {
                    this.museums = data.results;
                }.bind(this));
        };
    }])

    .controller("EditSnippetDialogCtrl", ["MediaService", "$q", "$snippet", function(MediaService, $q, $snippet) {
        this.$snippet = $snippet;

        /**
         * @ngDoc method
         * @name mediaList
         * @methodOf EditSnippetDialogCtrl
         * @returns {Promise} a promise for the media list.
         * @description get the media list for the exhibitions record.
         */
        this.mediaList = function() {
            var images = this.$snippet.images || [],
                results = [];

            if (images.length) {
                results = MediaService.find({where: {"_id": {"$in": images}}})
                    .then(function(items) {
                        items.forEach(function(item) {
                            item.uri = MediaService.link(item.id);
                            if (item.thumb) {
                                item.thumbUri = MediaService.thumbLink(item.id);
                            }
                        });
                        return items;
                    });
            }

            return $q.when(results);
        };

        /**
         * @ngDoc method
         * @name onIngestComplete
         * @methodOf EditSnippetDialogCtrl
         * @param mediaItem {Object} the ingested media item.
         * @description triggered when an image is uploaded via the rich text editor.
         */
        this.onIngestComplete = function(mediaItem) {
            // add the image id to the list of images bound to this record.
            var images = this.$snippet.images || (this.$snippet.images = []);
            images.push(mediaItem.id);
        };

        /**
         * @ngDoc method
         * @name onInsertMedia
         * @methodOf EditSnippetDialogCtrl
         * @param mediaItem {Object} the inserted media item
         * @description triggered when an image is inserted via the insert media modal.
         */
        this.onInsertMedia = function(mediaItem) {
            // add the image id to the list of images bound to this record.
            var images = this.$snippet.images || (this.$snippet.images = []),
                idx = images.indexOf(mediaItem.id);

            if (idx === -1) {
                images.push(mediaItem.id);
            }
        };
    }])
